<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <v-card class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Registrazione</v-toolbar-title>
            <v-spacer></v-spacer>

            <v-btn icon @click="gotoHome()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="mdi-account"
                v-model="name"
                label="Nome"
                type="text"
              ></v-text-field>

              <v-text-field
                prepend-icon="mdi-email"
                v-model="email"
                label="EMail"
                type="text"
              ></v-text-field>

              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                v-model="password"
                label="Password"
                type="password"
              ></v-text-field>

              <v-text-field
                id="confirmpassword"
                prepend-icon="mdi-lock"
                v-model="passwordconfirm"
                label="Conferma password"
                type="password"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" @click="register()"
              ><v-icon class="mr-2">mdi-account-plus</v-icon> Registrati</v-btn
            >
          </v-card-actions>
          <v-card-actions>
            <v-alert width="100%" v-show="userStore.error !== ''" type="error"
              >{{ userStore.error }}
            </v-alert>
          </v-card-actions>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn block color="secondary" @click="gotoLoginForm()"
              ><v-icon class="mr-2">mdi-login</v-icon>Già registrato?
              Accedi</v-btn
            >
          </v-card-actions>
        </v-card>

        <v-sheet width="100%" class="fill-height my-5" align="center" justify="center">
          Iscrivendoti accetti i <a href="#">termini del servizio</a> e <a href="https://noisplus.it/privacy-policy/" target="_blank">l'informativa sulla privacy</a>
        </v-sheet>

      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
export default {
  name: "RegisterView",
  data: () => ({
    version: "1,0",
    name: "",
    email: "",
    password: "",
    passwordconfirm: "",
    rememberme: false,
  }),
  computed: {
    ...mapStores(useUserStore),
  },
  mounted() {
    if (localStorage.getItem("rememberme") == "true") {
      this.rememberme = true;
      this.email = localStorage.getItem("email");
      this.password = localStorage.getItem("password");
    }
    console.log("auth mounted");
  },
  methods: {
    register() {
      this.userStore.signup({
        email: this.email,
        password: this.password,
      });
    },
    gotoLoginForm() {
      this.$router.push("login");
    },
    gotoHome() {
      this.$router.push("/");
    },
  },
};
</script>


