<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title
        ><v-img
          contain
          height="48"
          width="48"
          src="@/assets/nois-logo.png"
        ></v-img
      ></v-toolbar-title>
      
       
      <v-spacer></v-spacer>
      <v-btn to="/login" text>
        <span class="mr-2">Accedi</span>
      </v-btn>
      <v-btn color="primary" to="/register">
        <span class="mr-2">Registrati</span>
      </v-btn>
    </v-app-bar>
    <v-main>
      <router-view></router-view>
    </v-main>
    <v-snackbar
      v-model="uiStore.snackbar.show"
      :multi-line="true"
      :right="true"
      :top="true"
      :timeout="6000"
      :color="uiStore.snackbar.variant"
    >
      {{ uiStore.snackbar.message }}
      <v-btn
        dark
        text
        @click="uiStore.updateSnackbar({ show: false })"
      >
        Chiudi
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapStores } from 'pinia'
import { useUiStore } from '@/stores/UiStore'

export default {
  computed: {
    ...mapStores(useUiStore),
  },
  methods: {
  },
};
</script>


