import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useConfigStore = defineStore({
  // state
  id: 'config',
  state: () => {
    return {
      map: {
        url: 'https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png',
        attribution: '&copy; <a target="_blank" href="http://osm.org/copyright">OpenStreetMap</a> contributors'
      },
      emptyDocModel: {
        title: "title",
        subtitle: "subtitle",
        descpub: "description public",
        descfunct: "description functional",
        img: "https://via.placeholder.com/250x150",
        video: "youtube or vimeo embedded",
        season: {
          spring: false,
          summer: false,
          autumn: false,
          winter: false,
        },
        disabled: false,
        typology: "indoor",
        minduration: 15,
        theme: "storico",
        audience: "Pubblico di riferimento",
        location: {
          latLng: { "lat": 40.28522156518767, "lng": 8.888740092515947 },
          map: { center: { "lat": 40.28522156518767, "lng": 8.888740092515947 }, zoom: 15 }
        },
        tags: [],
        // Parte dedicata all'app (scheda)
        app: {
          note: "",
          updated: "",
          title: "",
          subtitle: "",
          description: "",
          imgbanner: "https://via.placeholder.com/250x150",
          sections: [

          ],
        },
        // Parte dedicata all'interazione (smart)
        interaction: {
          note: "",
          updated: "",
          type: "",
          model: "",
          // In teoria una sola sezione per un interazione (singolo progetto)
          // ma potrebbero in futuro esserci più apparecchi hardware per un singolo
          // progetto ... quindi per scaricare la "ricetta" si potrebbe utilizzare
          // sia id del progetto che sectionid. Se omesso sectionid prende la prima
          // section nella lista.
          sections: [],
        },
      },
      typologyList: [
        { "text": "Indoor", "value": "indoor" },
        { "text": "Outdoor", "value": "outdoor" },
        { "text": "Gamification", "value": "gamification" },
      ],
      mindurationList: [
        { "text": "30 min", "value": 30 },
        { "text": "60 min", "value": 60 },
        { "text": "90 min", "value": 90 },
        { "text": "120 min", "value": 120 },
      ],
      themeList: [
        { "text": "Storico", "value": "history" },
        { "text": "Artistico", "value": "art" },
        { "text": "Folcloristico", "value": "culture" },
        { "text": "Naturalistico", "value": "nature" },
      ],
      audienceList: [
        { "text": "Famiglia", "value": "family" },
        { "text": "Coppia", "value": "couple" },
        { "text": "Adulti", "value": "adult" },
        { "text": "Bambini", "value": "children" },
      ],
      emptyInfoItem: {
        "infoItemTitle": "",
        "infoItemMainPicture": "",
        "infoItemPreviewPicture": "",
        "infoItemMediaItem": [],
        "infoItemSubTitle": "",
        "infoItemPriority": "",
        "infoItemType": "",
        "infoItemDescription": "",
        "infoItemSpeechable": "",
        "infoItemSpeech": "",
        "infoItemNote": "",
        "infoItemPoints": [],
        "infoItemQuiz": [],
      },
      interactiontypes: [
        {
          title: 'Smart Box (RFID)',
          modeltype: 'smartboxrfid5'
        },
        {
          title: 'Smart Box (Audio standalone)',
          modeltype: 'smartboxaudiosa'
        },

      ],
      interactionmodels: {
        "smartboxrfid5": {
          "device": {
            "name": "smartbox001",
            "type": "SmartBox (Raspberry)",
            "devicetype": "smartbox",
            "url": "/webgui/videoplayer.html",
            "dateModified": "3.4",
            "location": {
              "area": "Indoor",
              "placename": "",
              "roomname": "",
              "floor": "",
              "position": "SmartBox 001"
            },
            "editui": {
              "groups": [
                {
                  "id": "id",
                  "label": "groupname",
                  "stations": [
                    {
                      "id": "1",
                      "label": "station name 1",
                      "mediaurl": "url",
                      "medianame": "filename",
                      "mediameta": "metadata",
                      "tagsid": [] // RFID tag number
                    },
                    {
                      "id": "2",
                      "label": "station name 2",
                      "mediaurl": "url",
                      "medianame": "filename",
                      "mediameta": "metadata",
                      "tagsid": [] // RFID tag number
                    },
                    {
                      "id": "3",
                      "label": "station name 3",
                      "mediaurl": "url",
                      "medianame": "filename",
                      "mediameta": "metadata",
                      "tagsid": [] // RFID tag number
                    },
                    {
                      "id": "4",
                      "label": "station name 4",
                      "mediaurl": "", // url
                      "medianame": "", // filename
                      "mediameta": "", // metadata
                      "tagsid": [] // RFID tag number
                    },
                    {
                      "id": "5",
                      "label": "station name 5",
                      "mediaurl": "url",
                      "medianame": "filename",
                      "mediameta": "metadata",
                      "tagsid": [] // RFID tag number
                    }

                  ]
                }
              ]
            },
            "interaction": {
              "name": "rbox013",
              "type": "loop",
              "volume": "",
              "deltaPIR": "",
              "media": [
                /*{
                  "id": "",
                  "title": "Slot video",
                  "subtitle": "",
                  "type": "video",
                  "remoteurl": "",
                  "version": "",
                  "modified": "",
                  "url": "",
                  "tags": ["1"]
                },*/

              ]
            }
          }
        },
        "smartboxaudiosa": {
          "device": {
            "name": "smartbox901",
            "type": "SmartBox (Audio Standalone)",
            "devicetype": "smartbox",
            "url": "/webgui/videoplayer.html",
            "dateModified": "3.4",
            "location": {
              "area": "Indoor",
              "placename": "",
              "roomname": "",
              "floor": "",
              "position": "SmartBox 001"
            },
            "interaction": {
              "name": "rbox013",
              "type": "loop",
              "volume": "",
              "deltaPIR": "",
              "media": [
                {
                  "id": "",
                  "title": "Selezionare file audio",
                  "subtitle": "",
                  "type": "audio",
                  "remoteurl": "",
                  "version": "",
                  "modified": "",
                  "url": "",
                  "tags": ["1"]
                },
                {
                  "id": "",
                  "title": "Selezionare file audio",
                  "subtitle": "",
                  "type": "audio",
                  "remoteurl": "",
                  "version": "",
                  "modified": "",
                  "url": "",
                  "tags": ["2"]
                },
                {
                  "id": "",
                  "title": "Selezionare file audio",
                  "subtitle": "",
                  "type": "audio",
                  "remoteurl": "",
                  "version": "",
                  "modified": "",
                  "url": "",
                  "tags": ["3"]
                },
                {
                  "id": "",
                  "title": "Selezionare file audio",
                  "subtitle": "",
                  "type": "audio",
                  "remoteurl": "",
                  "version": "",
                  "modified": "",
                  "url": "",
                  "tags": ["4"]
                },
                {
                  "id": "",
                  "title": "Selezionare file audio",
                  "subtitle": "",
                  "type": "audio",
                  "remoteurl": "",
                  "version": "",
                  "modified": "",
                  "url": "",
                  "tags": ["5"]
                }
              ]
            }
          }
        }
      }

    }
  },

  // actions 

  // getters
})