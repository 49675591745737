  <template>
  <v-card class="d-flex flex-column">
    <v-img height="150" :src="$imagekit.resize(item.img, 300)">
      <v-app-bar flat color="rgba(0, 0, 0, 0)">
        <v-icon color="white" v-if="item.deploystatus==1">mdi-checkbox-marked-circle</v-icon>
        <v-spacer></v-spacer>

        
      </v-app-bar>
    </v-img>
    <v-card-title>{{ item.title }}</v-card-title>
    <v-card-text>{{ item.subtitle }}</v-card-text>
    <v-spacer></v-spacer>
    <v-card-actions>
      <v-spacer></v-spacer>
      <v-btn color="primary" @click="editProject(item)"><!-- v-icon>mdi-pencil</v-icon --> Modifica </v-btn>
      <!-- v-btn color="secondary" @click="deleteProject(item.id)"> Elimina </v-btn -->
      <v-spacer></v-spacer>
      <v-menu top close-on-click>
        <template v-slot:activator="{ on, attrs }">
          <v-btn color="secondary" v-bind="attrs" v-on="on">Azioni <v-icon>mdi-dots-vertical</v-icon> </v-btn>
        </template>

        <v-list>
          <v-list-item v-for="(action, index) in actions" :key="index" @click="actionProject(item.id, action.value)">
            <v-list-item-title>{{ action.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
      <v-spacer></v-spacer>
    </v-card-actions>
  </v-card>
</template>


<script>
module.exports = {
  props: {
    item: Object,
    editProject: Function,
    actionProject: Function,
  },
  data() {
    return {
      actions: [
        {
          title: "Cancella",
          value: "delete",
        },
        {
          title: "Crea una copia",
          value: "clone",
        },
        {
          title: "Sposta in un altro gruppo",
          value: "move",
        },
      ],
    };
  },
};
</script>
