import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
import router from './router'
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { initializeApp } from "firebase/app";


// Image helper, optimizer
import {imagekit} from './functions/imagekit.js'
Vue.prototype.$imagekit = imagekit

// Translation flag icons
import LangFlag from 'vue-lang-code-flags';
Vue.component('lang-flag', LangFlag);

// Translation helper
import {trlang} from './functions/trlang.js'
Vue.prototype.$trlang = trlang

// import plugin
import { TiptapVuetifyPlugin } from 'tiptap-vuetify';
// don't forget to import CSS styles
import 'tiptap-vuetify/dist/main.css'
// Vuetify's CSS styles 
//import 'vuetify/dist/vuetify.min.css'

import { LMap, LTileLayer, LMarker } from 'vue2-leaflet';
import 'leaflet/dist/leaflet.css';

import VueDraggable from 'vuedraggable';

Vue.component('v-draggable', VueDraggable);
Vue.component('l-map', LMap);
Vue.component('l-tile-layer', LTileLayer);
Vue.component('l-marker', LMarker);

import { Icon } from 'leaflet';

delete Icon.Default.prototype._getIconUrl;
Icon.Default.mergeOptions({
  iconRetinaUrl: require('leaflet/dist/images/marker-icon-2x.png'),
  iconUrl: require('leaflet/dist/images/marker-icon.png'),
  shadowUrl: require('leaflet/dist/images/marker-shadow.png'),
});

import * as ui from "./components/ui";

Object.keys(ui).forEach((name) => {
  Vue.component(`Vs${name}`, ui[name]);
});

import * as editors from "./components/editors";

Object.keys(editors).forEach((name) => {
  Vue.component(`Vs${name}`, editors[name]);
});

Vue.config.productionTip = false

//import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyDL6KHWAnqixdeSB-8KvG4dgiQ3mAJujsA",
  authDomain: "smartcms-8d342.firebaseapp.com",
  projectId: "smartcms-8d342",
  storageBucket: "smartcms-8d342.appspot.com",
  messagingSenderId: "933724264856",
  appId: "1:933724264856:web:927fed9f25784a47a4b3ed",
  measurementId: "G-35PN2YXKHX"
};

// Initialize Firebase
const fbApp = initializeApp(firebaseConfig);
Vue.prototype.$fbApp = fbApp
console.log("initializeApp: main")

// use this package's plugin
Vue.use(TiptapVuetifyPlugin, {
  // the next line is important! You need to provide the Vuetify Object to this place.
  vuetify, // same as "vuetify: vuetify"
  // optional, default to 'md' (default vuetify icons before v2.0.0)
  iconsGroup: 'mdi'
})

import pinia from  "@/plugins/store"


import { useUserStore } from "@/stores/UserStore";
// reload VUE app on Firebase auth state change
onAuthStateChanged(getAuth(fbApp), (user) => {
  const userStore = useUserStore(pinia)
  if (user) {
    
    console.log(user)
    userStore.isLoggedIn = true
    userStore.user = user;
    userStore.loadProfileAsync();
  } else {
    userStore.isLoggedIn = false
    router.replace({
      path: "/"
    })
  }
  
  new Vue({
    router,
    vuetify,
    pinia,
    render: (h) => h(App),
  }).$mount("#app")
})
