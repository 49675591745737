<template>
  <v-container>
    <v-row>
      <v-col cols="6">
        <!-- vue-pannellum src="https://pannellum.org/images/bma-1.jpg" style="height: 500px;"></vue-pannellum -->
        <v-card class="mx-auto my-0">
          <vue-pannellum
            ref="vuepannellum"
            v-if="equirectangularUrl != ''"
            :src="equirectangularUrl"
            :debug="false"
            :auto-load="true"
            :show-zoom="true"
            :double-click-zoom="false"
            :show-fullscreen="false"
            :compass="true"
            :hotSpots="hotSpots"
            :hfov.sync="hfov"
            :yaw.sync="yaw"
            :pitch.sync="pitch"
            style="height: 500px"
          >
          </vue-pannellum>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="my-0"
              color="primary"
              @click="openMediaEditor('panorama')"
              >Seleziona Immagine 360</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

        <vs-info-item-part
          :openMediaEditor="openMediaEditor"
          :item="item"
          :layout="12"
          :lang="projectStore.curLang"
        >
        </vs-info-item-part>

        <!-- v-row align="stretch">
          <v-col cols="4">
            <v-card class="mx-auto my-6">
              <v-img height="150" :src="item.infoItemPreviewPicture"> </v-img>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  text
                  class="my-0"
                  color="primary"
                  @click="openMediaEditor('infoItemPreviewPicture')"
                  >Seleziona
                </v-btn>
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
          <v-col cols="8">
            <v-card class="mx-auto my-6">
              <v-card-text>
                <v-text-field
                  dense
                  label="Titolo"
                  v-model="item.infoItemTitle"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Sottotitolo"
                  v-model="item.infoItemSubTitle"
                ></v-text-field>
                <v-text-field
                  dense
                  label="Descrizione"
                  v-model="item.infoItemDescription"
                ></v-text-field>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row -->

        <!-- p>
          pitch (lat): {{ pitch }} - yaw (lng): {{ yaw }} - hfov: {{ hfov }}
        </p -->

        <!-- {{ hotSpots }} -->
      </v-col>
      <v-col cols="6">
        <v-card class="mx-auto" v-if="viewEdit == false">
          <v-toolbar>
            <!-- v-app-bar-nav-icon></v-app-bar-nav-icon -->

            <v-toolbar-title>Punti informativi</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="addPoi">
              <v-icon>mdi-plus</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- v-list two-line style="height: 435px; overflow-y: auto" -->
          <v-list two-line>
            <v-draggable v-model="item.infoItemPoints">
              <v-list-item
                v-for="(poi, index) in item.infoItemPoints"
                :key="index"
              >
                <v-list-item-avatar>
                  <v-icon> mdi-information </v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title v-text="poi.title"></v-list-item-title>

                  <v-list-item-subtitle
                    v-text="poi.text"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-action>
                  <v-btn icon @click="editPoi(poi, index)">
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                </v-list-item-action>

                <v-list-item-action>
                  <v-btn icon @click="deletePoi(poi, index)">
                    <v-icon>mdi-delete</v-icon>
                  </v-btn>
                </v-list-item-action>
              </v-list-item>
            </v-draggable>
          </v-list>
        </v-card>
        <v-card v-if="viewEdit" class="mx-auto">
          <v-toolbar>
            <v-app-bar-nav-icon @click="closeEditPoi()">
              <v-icon>mdi-arrow-left</v-icon>
            </v-app-bar-nav-icon>

            <v-toolbar-title>Modifica</v-toolbar-title>

            <v-spacer></v-spacer>

            <v-btn icon @click="deletePoi">
              <v-icon>mdi-delete</v-icon>
            </v-btn>
          </v-toolbar>

          <!-- v-list two-line style="height: 435px; overflow-y: auto" -->
          <v-card-text>
            <v-text-field
              label="Titolo"
              outlined
              v-model="curPoi.title"
            ></v-text-field>
            <v-textarea
              outlined
              v-model="curPoi.text"
              name="poitext"
              label="Decrizione"
            ></v-textarea>
            <!-- v-btn @click="changePoiPosition">Imposta posizione</v-btn -->
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <!-- code>{{ item }}</code -->
    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectImage"
    ></vs-media-editor>
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import VuePannellum from "../../components/ui/VuePannellum.vue";

import FormTheme from "../../mixins/formtheme";

export default {
  props: ["index"],
  name: "EditorPano",
  mixins: [FormTheme],
  components: {
    VuePannellum,
  },
  computed: {
    item() {
      return this.projectStore.project.app.sections[this.index];
    },
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  mounted() {
    if (this.item.infoItemMediaItem.length > 0) {
      this.equirectangularUrl = this.item.infoItemMediaItem[0];
    }
    this.loadHotSpots();
  },
  data: () => ({
    viewEdit: false,
    curPoiIndex: -1,
    curPoi: null,
    hfov: 90,
    yaw: 0,
    pitch: 0,
    pois: [],
    hotSpots: [],
    /*hotSpots: [
      {
        pitch: 13.502452729766706,
        yaw: 154.9920035869203,
        type: "info",
        text: "Chiesa",
        draggable:true,

      },
      {
        pitch: 0.66,
        yaw: 66,
        type: "info",
        text: "Silanus",
        draggable:true,
      },
    ],*/
    version: "1,0",
    equirectangularUrl: "",
  }),
  methods: {
    loadHotSpots() {
      const vm = this;
      console.log("loadHotSpots");
      // https://www.pluralsight.com/guides/install-npm-packages-from-gitgithub
      this.hotSpots = [];
      for (var i = 0; i < this.item.infoItemPoints.length; i++) {
        this.hotSpots.push({
          pitch: this.item.infoItemPoints[i].lat,
          yaw: this.item.infoItemPoints[i].lng,
          type: "info",
          text: this.item.infoItemPoints[i].title,
          draggable: true,
          dragHandlerFunc: vm.saveHotSpot,
          dragHandlerArgs: { id: i },
        });
      }
    },
    saveHotSpot(e, args, hs) {
      if (e.type == "mousedown") {
        console.log("mousedown - id:", args, hs);
      }
      if (e.type == "mouseup") {
        // Here I can update the hotSpot in my database by using args.id and hs.yaw, hs.pitch for the new position
        console.log("mouseup - id:" + args["id"], hs);

        this.item.infoItemPoints[args.id].lat = hs.pitch;
        this.item.infoItemPoints[args.id].lng = hs.yaw;

        this.editPoi(this.item.infoItemPoints[args.id], args.id);
      }
    },
    addPoi() {
      const vm = this;
      const newPoi = {
        x: 0.0,
        y: 0.0,
        z: 0.0,
        lng: vm.yaw,
        lat: vm.pitch,
        width: 50.0,
        height: 50.0,
        title: "Titolo",
        text: "Descrizione",
        icon: "https://s3-eu-west-1.amazonaws.com/mkspresstage.suggesto.eu/noisplus/app/res/exp4/info.png",
      };

      //this.set(this.projectStore.project.app.sections[this.index].infoItemPoints, this.index, newPoi);

      vm.projectStore.project.app.sections[this.index].infoItemPoints.push(
        newPoi
      );
      
      //const i = this.hotSpots.length;

      /*this.hotSpots.push({
          pitch: this.item.infoItemPoints[i].lat,
          yaw: this.item.infoItemPoints[i].lng,
          type: "info",
          text: this.item.infoItemPoints[i].title,
          draggable: true,
          dragHandlerFunc: vm.saveHotSpot,
          dragHandlerArgs: { id: i },
        });*/

      this.$refs.vuepannellum.viewer.addHotSpot({
        pitch: this.pitch,
        yaw: this.yaw,
        type: "info",
        text: newPoi.text,
        draggable: true,
        dragHandlerFunc: vm.saveHotSpot,
        dragHandlerArgs: { id: this.hotSpots.length},
      });

      //this.loadHotSpots();
    },
    editPoi(poi, index) {
      this.curPoi = poi;
      this.curPoiIndex = index;
      this.viewEdit = true;
    },
    closeEditPoi() {
      this.viewEdit = false;
    },
    changePoiPosition() {
      this.curPoi.lng = this.yaw;
      this.curPoi.lat = this.pitch;
      this.loadHotSpots();
    },
    deletePoi(poi, index) {
      alert("deletePoi con conferma", poi);
      this.projectStore.project.app.sections[this.index].infoItemPoints.splice(
        index
      );
    },
    openMediaEditor(resname) {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        { resname: resname, item:  this.item}
      );
    },
    onSelectImage(evn) {
      console.log("onSelectImage: ", evn);

      /*if (evn.options.resname == "infoItemPreviewPicture") {
        this.item.infoItemPreviewPicture = evn.url;
      } else {
        if (this.item.infoItemMediaItem.length == 0) {
          this.item.infoItemMediaItem.push(evn.url);
        } else {
          this.item.infoItemMediaItem[0] = evn.url;
        }
        this.equirectangularUrl = evn.url;
      }*/

      if (evn.options.resname == "panorama") {
        if (this.item.infoItemMediaItem.length <= 0) {
          this.item.infoItemMediaItem.push("");
        }
        //evn.options.item.infoItemMediaItem[0] = evn.url;
        this.item.infoItemMediaItem[0] = evn.url;
        this.equirectangularUrl = this.item.infoItemMediaItem[0];
        
      } else if (evn.options.resname == "infoItemMainPicture") {
        this.item.infoItemPreviewPicture = evn.url;
        this.item.infoItemMainPicture = evn.url;
      }  else {
        this.item[evn.options.resname] = evn.url;
      }
    },
  },
};
</script>
