 <template>
  <v-container>
    <v-row class="my-3">
      <v-col>
        <v-btn color="primary" @click="addGroup()">Aggiungi gruppo</v-btn>
      </v-col>
      <v-col class="text-right">
        <v-btn color="secondary" @click="removeGroup(groupindex)"
          >Rimuovi questo gruppo</v-btn
        >
      </v-col>
    </v-row>

    <v-tabs show-arrows v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab
        v-for="(groupItem, index) in item.device.editui.groups"
        :key="'tab-' + index"
      >
        {{ index + 1 }} - {{ groupItem.label }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item
          class="pa-1"
          v-for="(groupItem, groupindex) in item.device.editui.groups"
          :key="'tabitem-' + groupindex"
        >
          <v-row class="mt-3">
            <v-col cols="4">
              <v-text-field
                dense
                outlined
                v-model="groupItem.label"
              ></v-text-field>
            </v-col>
          </v-row>

          <v-card
            class="my-2"
            color="#EEEEEE"
            v-for="(stationItem, stationindex) in groupItem.stations"
            :key="'station-' + stationindex"
          >
            <v-card-text>
              <v-row>
                <v-col cols="4">
                  <v-text-field
                    label="Nome stazione"
                    dense
                    outlined
                    v-model="stationItem.label"
                  ></v-text-field>
                </v-col>
                <v-col cols="4">
                  <v-btn @click="openMediaEditor('mediaurl', stationItem)"
                    >Seleziona video</v-btn
                  >
                  <video
                    :key="stationItem.mediaurl"
                    class="mt-2"
                    style="max-width: 100%"
                    :src="stationItem.mediaurl"
                    controls
                  ></video>
                  {{ stationItem.medianame }}
                </v-col>
                <v-col>
                  <v-text-field
                    label="Inserisci tag RFID"
                    dense
                    outlined
                    v-model="newTagid"
                    append-outer-icon="mdi-plus"
                    @click:append-outer="addTagsID(stationItem.tagsid)"
                  ></v-text-field>

                  <v-chip-group column>
                    <v-chip
                      color="primary"
                      @click:close="removeTagsID(stationItem.tagsid, tag)"
                      close
                      v-for="tag in stationItem.tagsid"
                      :key="tag"
                    >
                      {{ tag }}
                    </v-chip>
                  </v-chip-group>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>
    <ConfirmDlg ref="confirmdelgroup" />
  </v-container>
</template>
    
<script>
module.exports = {
  components: {
    ConfirmDlg: () => import("../../components/ui/ConfirmDlg"),
  },
  props: {
    item: Object,
    layout: Number,
    openMediaEditor: Function,
    lang: String,
  },
  data() {
    return {
      newTagid: "",
      tab: null,
      edituiref: null,
      iconType: {
        text: "mdi-code-tags",
        video: "mdi-video",
        audio: "mdi-volume-high",
      },
    };
  },
  mounted() {
    this.edituiref = this.item.device.editui;
  },
  watch: {
    edituiref: {
      handler(newValue) {
        //console.log("test: ", JSON.stringify(newValue, null, 2));
        this.item.device.interaction.media = [];
        for (var g = 0; g < newValue.groups.length; g++) {
          for (var s = 0; s < newValue.groups[g].stations.length; s++) {
            const si = newValue.groups[g].stations[s];
            // Copy of tags
            var tags = JSON.parse(JSON.stringify(si.tagsid));
            // Add stationid as tag (for combination)
            tags.push(si.id);
            const sm = {
              id: si.medianame,
              group: newValue.groups[g].id,
              type: si.mediameta,
              title: si.label,
              subtitle: "",
              url: si.mediaurl,
              remoteurl: si.mediaurl,
              tags: tags,
              modified: "",
              version: "",
            };

            this.item.device.interaction.media.push(sm);
          }
        }
        
      },
      deep: true,
    },
  },
  getters: {
    getlang() {
      if (this.lang) {
        return this.lang;
      } else {
        return "it";
      }
    },
  },
  methods: {
    async removeGroup(idx) {
      if (
        await this.$refs.confirmdelgroup.open(
          "Conferma",
          "Sei sicuro di voler rimuovere questo gruppo?"
        )
      ) {
        this.item.device.editui.groups.splice(idx, 1);
      }
    },
    addGroup() {
      var clonedGroup = JSON.parse(
        JSON.stringify(this.item.device.editui.groups[0])
      );
      this.item.device.editui.groups.push(clonedGroup);
    },
    addTagsID(taglist) {
      if (taglist.indexOf(this.newTagid) < 0) {
        taglist.push(this.newTagid);
      }
    },
    removeTagsID(taglist, tag) {
      for (var i = taglist.length - 1; i >= 0; i--) {
        if (tag == taglist[i]) {
          taglist.splice(i, 1);
        }
      }
    },
  },
};
</script>