<template>
  <v-container>
   <iframe style="width:100%;height:3000px;border:none;" src="https://noisplus.it/tutorial/"></iframe>
  </v-container>
</template>

<script>
export default {
  name: "HelpView",

  data: () => ({
    version: "1,0",
  }),
  mounted() {
    // Load all experiences (or the latest experiences ...)
  },
  methods: {
    example() {
      //Method placeholder
    },
  },
};
</script>
