<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h5>Statistiche</h5>
          </v-card-title>
        </v-card>
      </v-col>
    </v-row>

    <v-row>
      <v-col>
        <div id="widgetIframe">
          <iframe
            width="100%"
            height="350"
            src="https://matomo.suggesto.eu/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=UserCountryMap&actionToWidgetize=realtimeMap&idSite=43&period=day&date=today&disableLink=1&widget=1"
            scrolling="yes"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>

        <div id="widgetIframe">
          <iframe
            width="100%"
            height="400"
            src="https://matomo.suggesto.eu/index.php?module=Widgetize&action=iframe&disableLink=0&widget=1&moduleToWidgetize=VisitTime&actionToWidgetize=getByDayOfWeek&idSite=43&period=day&date=today&disableLink=1&widget=1"
            scrolling="yes"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </v-col>
      <v-col>
        <div id="widgetIframe">
          <iframe
            width="100%"
            height="1000"
            src="https://matomo.suggesto.eu/index.php?module=Widgetize&action=iframe&containerId=VisitOverviewWithGraph&disableLink=0&widget=1&moduleToWidgetize=CoreHome&actionToWidgetize=renderWidgetContainer&idSite=43&period=day&date=today&disableLink=1&widget=1"
            scrolling="yes"
            frameborder="0"
            marginheight="0"
            marginwidth="0"
          ></iframe>
        </div>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "StatView",

  data: () => ({
    version: "1,0",
    drawer: true,
    items: [
      { title: "Visualizzazioni", icon: "mdi-chart-areaspline-variant" },
      { title: "Tipologia visitatore", icon: "mdi-chart-pie" },
      { title: "Lingua selezionata", icon: "mdi-chart-pie" },
      { title: "Classifica esperienze", icon: "mdi-chart-box" },
    ],
  }),
  mounted() {
    //google.charts.load('current', { 'packages': ['line'] });
  },
  methods: {
    example() {
      //Method placeholder
    },
  },
};
</script>
