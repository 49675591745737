<template>
  <v-app>
    <v-app-bar app style="z-index: 1200">
      <v-app-bar-nav-icon to="/projects">
        <v-icon>mdi-arrow-left</v-icon>
      </v-app-bar-nav-icon>
      <v-toolbar-title >
          <v-btn to="/projects" text> Elenco esperienze</v-btn>
      </v-toolbar-title>

      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn width="200" text :to="getRouteWithId('/project/details/')">
          <span class="mr-2">Progetto</span>
          <!-- v-icon>mdi-open-in-new</v-icon -->
        </v-btn>

        <v-btn width="200" text :to="getRouteWithId('/project/app/')">
          <span class="mr-2">App</span>
          <!-- v-icon>mdi-open-in-new</v-icon -->
        </v-btn>
        <v-btn width="200" text :to="getRouteWithId('/project/interactive/')">
          <span class="mr-2">Interattività</span>
          <!-- v-icon>mdi-open-in-new</v-icon -->
        </v-btn>
        <v-btn width="200" text :to="getRouteWithId('/project/deploy/')">
          <span class="mr-2">Distribuzione</span>
          <!-- v-icon>mdi-open-in-new</v-icon -->
        </v-btn>
      </v-toolbar-items>
      <v-spacer></v-spacer>
      {{ userStore.getGroupNameById(userStore.curGroup) }}
      <!-- {{ projectStore.project.title }} -->
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <!-- v-btn @click="saveProject()">Save project</v-btn -->

    <v-btn
      color="primary"
      elevation="2"
      fab
      large
      fixed
      bottom
      right
      :loading="projectStore.saving"
      :disabled="projectStore.saving"
      @click="projectStore.updateProject()"
      ><v-icon>mdi-content-save</v-icon>
    </v-btn>

    <v-snackbar
      v-model="uiStore.snackbar.show"
      :multi-line="true"
      :right="false"
      :top="false"
      :timeout="2000"
      :color="uiStore.snackbar.variant"
    >
      {{ uiStore.snackbar.message }}
      <v-btn dark text @click="uiStore.updateSnackbar({ show: false })">
        Chiudi
      </v-btn>
    </v-snackbar>

    <ConfirmDlg ref="confirm" />
  </v-app>
</template>


<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import { useUiStore } from "@/stores/UiStore";

export default {
  components: {
    ConfirmDlg: () => import("../components/ui/ConfirmDlg"),
  },
  computed: {
    ...mapStores(useUserStore, useConfigStore, useProjectStore, useUiStore),
  },
  methods: {
    getRouteWithId(routeName) {
      return routeName + this.$route.params.id;
    },
  },
};
</script>