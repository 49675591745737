<template>
  <v-container>

    <vs-info-item-part
      :openMediaEditor="openMediaEditor"
      :item="item"
      :layout="6"
      :lang="projectStore.curLang"
    >
    </vs-info-item-part>

    <v-row>
      <v-col cols="3">
        <v-select
          dense
          outlined
          label="Tipologia quiz"
          v-model="item.infoItemModel"
          :items="quizModels"
        >
        </v-select>
      </v-col>
      <v-col cols="3">
        <v-btn color="primary" 
        v-if="item.infoItemModel"
        block
        @click="addInfoItemQuiz()"
          ><v-icon>mdi-plus</v-icon> Aggiungi domanda</v-btn
        >
        <v-alert dense type="info" v-if="!item.infoItemModel">Seleziona tipologia di quiz</v-alert>
      </v-col>
    </v-row>

    <!-- Quiz Text -->
    <v-tabs v-if="item.infoItemModel == 'quizText'" show-arrows v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab
        v-for="(quizItem, index) in item.infoItemQuiz"
        :key="'tab-' + index"
      >
        Domanda {{ index + 1 }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(quizItem, index) in item.infoItemQuiz"
          :key="'tabitem-' + index"
        >
          <v-row>
            <v-col cols="6">
              <v-card class="ma-2">
                <v-img height="150" contain :src="$imagekit.resize(quizItem.img, 300)"> </v-img>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="openMediaEditor('img', quizItem)"
                    >Seleziona
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
                <v-card-text>
                  <v-text-field
                    class="mt-2"
                    outlined
                    dense
                    hide-details="auto"
                    label="Titolo"
                    v-model="quizItem.title"
                  ></v-text-field>

                  <v-textarea
                    class="mt-2"
                    outlined
                    dense
                    hide-details="auto"
                    label="Descrizione"
                    v-model="quizItem.desc"
                  ></v-textarea>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="removeInfoItemQuiz(index)"
                    >Cancella questa domanda
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
                <!-- {{ quizItem }} -->
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="ma-2">
                <v-card-title> Risposte </v-card-title>

                <template v-for="(answerItem, answerIndex) in quizItem.answers">
                  <!-- v-card-subtitle  :key="`${answerIndex}-answerItem-cardsubtitle`">
                       
                    </v-card-subtitle -->
                  <!-- v-card-text :key="`${answerIndex}-answerItem-cardsubtitle`">Risposta: {{answerIndex+1}}</v-card-text -->
                  <v-card-text :key="`${answerIndex}-answerItem-cardtext`">
                    <v-text-field
                      class="mt-2"
                      outlined
                      :label="`Risposta: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-text`"
                      dense
                      hide-details="auto"
                      v-model="answerItem.text"
                    >
                    </v-text-field>

                    <v-textarea
                      class="my-2"
                      auto-grow
                      outlined
                      rows="1"
                      :label="`Spiegazione: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-answtext`"
                      dense
                      hide-details="auto"
                      v-model="answerItem.answtext"
                    >
                    </v-textarea>

                    <!-- v-numeric 
                      outlined
                      text
                      useCalculator="false"
                      :label="`Punteggio: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-score`" 
                      dense
                      hide-details="auto"
                      v-model.number="answerItem.score">
                    </v-numeric -->

                    <v-text-field
                      class="mt-2"
                      outlined
                      :label="`Punteggio: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-score`"
                      dense
                      type="number"
                      hide-details="auto"
                      v-model.number="answerItem.score"
                    >
                    </v-text-field>
                  </v-card-text>
                  <v-divider
                    :key="`${answerIndex}-answerItem-divider`"
                  ></v-divider>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <!-- Quiz Image -->
    <v-tabs v-if="item.infoItemModel == 'quizImages'" show-arrows v-model="tab">
      <v-tabs-slider></v-tabs-slider>

      <v-tab
        v-for="(quizItem, index) in item.infoItemQuiz"
        :key="'tab-' + index"
      >
        Domanda {{ index + 1 }}
      </v-tab>

      <v-tabs-items v-model="tab">
        <v-tab-item
          v-for="(quizItem, index) in item.infoItemQuiz"
          :key="'tabitem-' + index"
        >
          <v-row>
            <v-col cols="6">
              <v-card class="ma-2">
                <!-- v-img height="150" :src="quizItem.img"> </v-img>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="primary"
                    text
                    @click="openMediaEditor('img', quizItem)"
                    >Seleziona
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions -->
                <v-card-text>
                  <v-text-field
                    class="mt-2"
                    outlined
                    dense
                    hide-details="auto"
                    label="Titolo"
                    v-model="quizItem.title"
                  ></v-text-field>

                  <v-textarea
                    class="mt-2"
                    outlined
                    dense
                    hide-details="auto"
                    label="Descrizione"
                    v-model="quizItem.desc"
                  ></v-textarea>
                </v-card-text>

                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn color="primary" text @click="removeInfoItemQuiz(index)"
                    >Cancella questa domanda
                  </v-btn>
                  <v-spacer></v-spacer>
                </v-card-actions>
                <!-- {{ quizItem }} -->
              </v-card>
            </v-col>
            <v-col cols="6">
              <v-card class="ma-2">
                <v-card-title> Risposte </v-card-title>

                <template v-for="(answerItem, answerIndex) in quizItem.answers">
                  <!-- v-card-subtitle  :key="`${answerIndex}-answerItem-cardsubtitle`">
                       
                    </v-card-subtitle -->
                  <!-- v-card-text :key="`${answerIndex}-answerItem-cardsubtitle`">Risposta: {{answerIndex+1}}</v-card-text -->
                  <v-card-text :key="`${answerIndex}-answerItem-cardtext`">
                    <v-text-field
                      class="mt-2"
                      outlined
                      :label="`Risposta: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-text`"
                      dense
                      hide-details="auto"
                      v-model="answerItem.text"
                    >
                    </v-text-field>

                    <v-img
                      contain
                      height="100"
                      :src="$imagekit.resize(answerItem.img, 300)"
                      @click="openMediaEditor('answerItem', answerItem)"
                    >
                      <v-btn text class="my-0" color="primary"
                        >Seleziona immagine
                      </v-btn>
                    </v-img>

                    <v-textarea
                      class="my-2"
                      auto-grow
                      outlined
                      rows="1"
                      :label="`Spiegazione: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-answtext`"
                      dense
                      hide-details="auto"
                      v-model="answerItem.answtext"
                    >
                    </v-textarea>

                    <!-- v-numeric 
                      outlined
                      text
                      useCalculator="false"
                      :label="`Punteggio: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-score`" 
                      dense
                      hide-details="auto"
                      v-model.number="answerItem.score">
                    </v-numeric -->

                    <v-text-field
                      class="mt-2"
                      outlined
                      :label="`Punteggio: ${answerIndex + 1}`"
                      :key="`${answerIndex}-answerItem-score`"
                      dense
                      type="number"
                      hide-details="auto"
                      v-model.number="answerItem.score"
                    >
                    </v-text-field>
                  </v-card-text>
                  <v-divider
                    :key="`${answerIndex}-answerItem-divider`"
                  ></v-divider>
                </template>
              </v-card>
            </v-col>
          </v-row>
        </v-tab-item>
      </v-tabs-items>
    </v-tabs>

    <!-- code>{{ item }}</code -->
    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectImage"
    ></vs-media-editor>
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";

export default {
  props: ["index"],
  name: "EditorQuiz",
  mixins: [FormTheme],
  computed: {
    item() {
      return this.projectStore.project.app.sections[this.index];
    },
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  mounted() {
    // Do something during load
  },
  data: () => ({
    tab: null,
    e1: 0,
    steps: 2,

    version: "1.0",
    quizJsonModels: {
      quizText: {
        title: "1. Titolo domanda",
        img: "https://via.placeholder.com/800x600?text=IMMAGINE",
        desc: "Descrizione della domanda",
        rightdesc: "",
        answers: [
          {
            order: 1,
            text: "A. Prima risposta",
            answtext: "(esempio) Mi dispiace! La risposta esatta era la C",
            img: "",
            score: 0,
          },
          {
            order: 2,
            text: "B. Seconda risposta",
            answtext: "(esempio) Mi dispiace! La risposta esatta era la C",
            img: "",
            score: 0,
          },
          {
            order: 3,
            text: "C. Terza risposta ",
            answtext: "(esempio) Esatto! Eventuale spiegazione della risposta",
            img: "",
            score: 1,
          },
          {
            order: 4,
            text: "D. Quarta risposta ",
            answtext: "(esempio) Mi dispiace! La risposta esatta era la C",
            img: "",
            score: 0,
          },
        ],
      },
      quizImages: {
        title: "Domanda 1",
        img: "",
        desc: "(esempio) qual'è il colore corretto ?",
        rightdesc: "",
        answers: [
          {
            order: 1,
            text: "A.",
            answtext: "(esempio) Esatto !",
            img: "https://via.placeholder.com/250x250?text=IMMAGINE",
            score: 1,
          },
          {
            order: 2,
            text: "B.",
            answtext: "(esempio) Mi dispiace! La risposta esatta era la A",
            img: "https://via.placeholder.com/250x250?text=IMMAGINE",
            score: 0,
          },
          {
            order: 3,
            text: "C.",
            answtext: "(esempio) Mi dispiace! La risposta esatta era la A",
            img: "https://via.placeholder.com/250x250?text=IMMAGINE",
            score: 0,
          },
        ],
      },
    },
    quizModels: [
      {
        text: "Quiz Testo",
        value: "quizText",
      },
      {
        text: "Quiz Immagini",
        value: "quizImages",
      },
    ],
  }),
  methods: {
    openMediaEditor(resname, item) {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        { resname: resname, item: item }
      );
    },
    onSelectImage(evn) {
      console.log("onSelectImage: ", evn);
      if (evn.options.resname == "infoItemMainPicture") {
        this.item.infoItemPreviewPicture = evn.url;
        this.item.infoItemMainPicture = evn.url;
      } else {
        console.log(evn.options);
        evn.options.item.img = evn.url;
      }
    },
    addInfoItemQuiz() {
      // Create a copy of the model
      if (typeof this.item.infoItemModel !== "undefined") {
        if (this.item.infoItemModel != "") {
          var newQuest = JSON.parse(
            JSON.stringify(this.quizJsonModels[this.item.infoItemModel])
          );
          this.item.infoItemQuiz.push(newQuest);
        }
      }
    },
    removeInfoItemQuiz(index) {
      this.item.infoItemQuiz.splice(index, 1);
    },
  },
};
</script>
