export default {
    data: function () {
      return {
        form: {
            dense: true,
            outlined: true,
            autogrow: true,
            counter: true
        }
      }
    }
  }