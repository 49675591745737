<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h5>Gestione Gruppi</h5>
          </v-card-title>
          <v-card-title>
            <v-row>
              <v-col>
                <v-text-field
                  outlined
                  v-model="search"
                  append-icon="mdi-magnify"
                  label="Search"
                  single-line
                  hide-details
                  dense
                ></v-text-field>
              </v-col>
              <v-col class="text-right">
                <v-btn color="primary" @click="addGroup()"><v-icon>mdi-plus</v-icon> Aggiungi nuovo gruppo</v-btn>
              </v-col>
            </v-row>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="userStore.userGroups"
            :search="search"
          >
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Nuovo gruppo</span>
                  </v-card-title>

                  <v-card-text>
                    <v-container>
                      <v-row>
                        <!-- nuovo gruppo -->
                        <v-text-field v-model="newgroup"></v-text-field>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Annulla
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Conferma
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import FormTheme from "../mixins/formtheme"; // TODO vedere come è meglio strutturarlo

import { mapStores } from "pinia";

import { useConfigStore } from "@/stores/ConfigStore";

import { useUserStore } from "@/stores/UserStore";

export default {
  name: "ConfigadminView",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
    search: "",
    dialog: false,
    values: [],
    headers: [
      { text: "Id", value: "id" },
      { text: "Label", value: "label" },
    ],
    newgroup: "",
  }),
  mounted() {
    this.userStore.loadGroups();
  },
  components: {
    ConfirmDlg: () => import("../components/ui/ConfirmDlg"),
  },
  computed: {
    ...mapStores(useConfigStore, useUserStore),
  },
  methods: {
    addGroup() {
      this.newgroup = "";
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    save() {
      if (this.newgroup != '') {
        this.userStore.addNewGroup(this.newgroup);

      }
      this.dialog = false;
    },
  },
};
</script>
