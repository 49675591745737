<template>
  <v-btn small outlined color="#AAAAAA">
    <lang-flag :iso="lang" :squared="false" />
  </v-btn>
</template>


<script>
module.exports = {
  props: {
    lang: String,
  },
};
</script>
