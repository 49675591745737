/*

Install
in main.js

import {trlang} from './functions/trlang.js'
Vue.prototype.$trlang = trlang

Original
var project = {
    app: {
        "title": "",
        "subtitle": "",
        "description": ""
    }
}

Translated
var project = {
    app: {
        "title": "",
        "subtitle": "",
        "description": "",
        "i18n": {
            "en": {
                "title": "",
                "subtitle": "",
                "description": ""
            }
        }
    }
}

$trlang.tr(project.app, "en").title = "ciao en 44444";
Se it, viene tornato objPath
Se en o altra lingua, viene tornato objPath['i8n'][lang]

*/

export const trlang = {
    tr: function (objPath, lang) {

        if (typeof(lang) == 'undefined') {
            return objPath
        }

        if (lang == '') {
            return objPath
        }

        if (lang === "it") {
            return objPath
        } else {
            objPath["i18n"] = objPath["i18n"] || {}
            objPath["i18n"][lang] = objPath["i18n"][lang] || {}
            return objPath["i18n"][lang]
        }
    }
}