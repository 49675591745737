import { defineStore } from 'pinia'
import { getFirestore } from "firebase/firestore";
import { collection, increment, doc, addDoc, setDoc, updateDoc, deleteDoc, getDoc, getDocs, query, where } from "firebase/firestore";

import { useUserStore } from './UserStore';
import { useConfigStore } from './ConfigStore';
import { useUiStore } from './UiStore';

import router from "../router";

function compare_experiences(a, b) {

    /*if (a.vorder < b.vorder) {
        return -1;
    }
    if (a.vorder > b.vorder) {
        return 1;
    }*/

    if (a.title < b.title) {
        return -1;
    }
    if (a.title > b.title) {
        return 1;
    }

    return 0;
}


// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useProjectStore = defineStore({
    // state
    id: 'project',
    state: () => {
        return {
            project: {

            }, // Current Project
            projects: [],
            models: [],
            db: {
                projects: []
            },
            curLang: 'it',
            languages: [
                { label: 'Italiano (it)', id: 'it' },
                { label: 'Inglese (en)', id: 'en' },
            ],

            loading: false,
            saving: false,
            collectionName: "projects"
        }
    },
    actions: {
        // FireStore
        changeLang(newLang) {
            this.curLang = newLang;
        },
        addProject() {
            this.addProjectAsync();
        },
        addInteractiveSection(payload) {
            this.addInteractiveSectionAsync(payload);
        },
        async addInteractiveSectionAsync(payload) {
            console.log("addInteractiveSectionAsync", payload)
            const db = getFirestore(this.$fbApp);
            const configStore = useConfigStore();

            if (this.project['id']) {
                if (this.project['id'] != '') {
                    if (typeof (this.project.interaction['id']) == 'undefined') {
                        const counterRef = doc(db, "counters", "interactiveid");

                        // Atomically increment the population of the city by 50.
                        await updateDoc(counterRef, {
                            nextid: increment(1)
                        });
                        const nextidres = await (await getDoc(counterRef)).data();
                        const code = nextidres.nextid+"" + Math.floor(1000 + Math.random() * 9000);
                        console.log("addInteractiveSection: ", nextidres, code)

                        this.project.interaction['id'] = code;
                        
                    }

                    if (this.project.interaction.sections.length <= 0) {
                        const jsonIntModel = JSON.parse(JSON.stringify(configStore.interactionmodels[payload]));
                        this.project.interaction.model = payload;
                        this.project.interaction.sections.push(jsonIntModel);
                    }
                }
            }
        },
        async addProjectAsync() {
            const db = getFirestore(this.$fbApp);
            const userStore = useUserStore();
            const configStore = useConfigStore();
            try {
                this.project = JSON.parse(JSON.stringify(configStore.emptyDocModel));
                this.project['group'] = userStore.curGroup;
                const docRef = await addDoc(collection(db, this.collectionName), this.project);
                this.project['id'] = docRef.id;
            } catch (e) {
                console.error("Error adding document: ", e);
            }

            this.loadProjects("caled from: Project store - addProjectAsync ");
        },
        updateProject() {
            this.updateProjectAsync();
        },
        async updateProjectAsync() {
            this.saving = true;
            const uiStore = useUiStore();
            const db = getFirestore(this.$fbApp);
            try {
                var jsonProject = JSON.parse(JSON.stringify(this.project));
                console.log("updateProjectAsync", this.project.id);
                await setDoc(doc(db, this.collectionName, this.project.id), jsonProject);
            } catch (e) {
                console.error("store - updateProjectAsync error: ", e);
            }
            uiStore.updateSnackbar({
                show: true,
                message: "Progetto salvato correttamente.",
            });
            this.saving = false;
        },
        copyProject(payload) {
            alert('copyProject', payload)
        },
        cloneProject(payload) {
            this.cloneProjectAsync(payload.id, payload.groupId, payload.modelId);
        },
        async cloneProjectAsync(id, groupId, modelId) {
            const vms = this;
            this.loading = true;
            const uiStore = useUiStore();
            const db = getFirestore(this.$fbApp);
            const docRef = doc(db, vms.collectionName, id)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                var clonedProject = docSnap.data();
                clonedProject['title'] = 'Copia ' + clonedProject['title'];
                clonedProject['group'] = groupId;
                clonedProject['fromModel'] = modelId;
                await addDoc(collection(db, vms.collectionName), clonedProject);
            } else {
                console.log("loadProjectAsync No such document!");
            }
            await this.loadProjectsAsync("cloneProjectAsync");
            uiStore.updateSnackbar({
                show: true,
                message: "Progetto creato correttamente.",
            });
            this.loading = false;
        },
        deleteProject(payload) {
            this.deleteProjectAsync(payload);
        },
        async deleteProjectAsync(payload) {
            const db = getFirestore(this.$fbApp);
            try {
                await deleteDoc(doc(db, this.collectionName, payload.id));
            } catch (e) {
                console.error("store - deleteProjectAsync error: ", e);
            }
            this.project = {}
            this.loadProjects("caled from: Project store - deleteProjectAsync ")
        },
        editProject(payload) {
            this.editProjectAsync(payload);
        },
        async editProjectAsync(payload) {
            router.push("/project/details/" + payload.id); // TODO PASSARE ID DEL DOCUMENTO DA CARICARE...
        },
        loadProject(id) {
            if (id !== this.project["id"]) {
                this.loadProjectAsync(id);
            } else {
                this.loading = false;
            }
        },
        async loadProjectAsync(id) {
            const vms = this;
            this.loading = true;
            const db = getFirestore(this.$fbApp);
            const docRef = doc(db, this.collectionName, id)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                vms.project = docSnap.data();
                vms.project['id'] = id;
                if (typeof (vms.project['deploystatus']) == 'undefined') {
                    vms.project['deploystatus'] = 0; // Se non esiste lo stato, lo imposto non pubblico
                }
                if (typeof (vms.project['beaconmajmin']) == 'undefined') {
                    vms.project['beaconmajmin'] = '';
                }
                
            } else {
                console.log("loadProjectAsync No such document!");
            }
            this.loading = false;
        },
        changeProjectGroup(payload) {
            this.changeProjectGroupAsync(payload.id, payload.groupId)
        },
        async changeProjectGroupAsync(id, groupId) {
            const vms = this;
            this.loading = true;
            const db = getFirestore(this.$fbApp);
            await updateDoc(doc(db, vms.collectionName, id), { group: groupId })
            await vms.loadProjectsAsync("changeProjectGroupAsync");
            this.loading = false;
        },
        loadProjects(payload) {
            this.loadProjectsAsync(payload);
        },
        async loadProjectsAsync(payload) {
            console.log(payload);
            const userStore = useUserStore();
            const vms = this;
            if (typeof (userStore.curGroup) == 'undefined') {
                return
            }
            if (userStore.curGroup == '') {
                userStore.curGroup = userStore.user.uid;
            }

            const db = getFirestore(this.$fbApp);
            const q = query(collection(db, this.collectionName), where("group", "==", userStore.curGroup));
            const querySnapshot = await getDocs(q);
            vms.projects = [];

            querySnapshot.forEach((doc) => {
                var docData = doc.data();
                docData['id'] = doc.id;
                vms.projects.push(docData);
            });

            vms.projects.sort(compare_experiences);

        },
        loadModels() {
            this.loadModelsAsync();
        },
        async loadModelsAsync() {
            const vms = this;
            const db = getFirestore(this.$fbApp);
            const q = query(collection(db, this.collectionName), where("group", "==", "emQARQ99SBj7k4gO5PV1"));
            const querySnapshot = await getDocs(q);
            vms.models = [];

            querySnapshot.forEach((doc) => {
                var docData = doc.data();
                docData['id'] = doc.id;
                vms.models.push(docData);
            });

            vms.models.sort(compare_experiences);

        },
    },
    getters: {}
})

/* 

 setDoc(state, payload) {
        console.log("setDoc:", payload)
        state.doc = payload.doc;
    },
    setDocAppSection(state, payload) {
        state.doc.app.sections[payload.index] = payload.section;
    },
  
    */