import Vue from 'vue'
import Router from 'vue-router'

import HomeLayout from '@/layouts/HomeLayout'
import HomeView from '@/views/HomeView'

import LoginLayout from '@/layouts/LoginLayout'
import LoginView from '@/views/LoginView'
import RegisterView from '@/views/RegisterView'

import DashboardLayout from '@/layouts/DashboardLayout'
import ProjectsView from '@/views/ProjectsView'
import RoutesView from '@/views/RoutesView'
import WidgetsView from '@/views/WidgetsView'
import StatsView from '@/views/StatsView'
import ProfileView from '@/views/ProfileView'
import UseradminView from '@/views/UseradminView'
import ConfigadminView from '@/views/ConfigadminView'
import HelpView from '@/views/HelpView'

import ProjectLayout from '@/layouts/ProjectLayout'
import ProjectDetails from '@/views/project/ProjectDetails'
import ProjectApp from '@/views/project/ProjectApp'
import ProjectInteractive from '@/views/project/ProjectInteractive'
import ProjectDeploy from '@/views/project/ProjectDeploy'

import EditorLayout from '@/layouts/EditorLayout'
import EditorGallery from '@/views/editors/EditorGallery'
import EditorPano from '@/views/editors/EditorPano'
import EditorAudio from '@/views/editors/EditorAudio'
import EditorVideo from '@/views/editors/EditorVideo'
import EditorQuiz from '@/views/editors/EditorQuiz'
import EditorEmbed from '@/views/editors/EditorEmbed'
/*import pinia from "@/plugins/store.js";
import { useUserStore } from '@/stores/UserStore'*/
Vue.use(Router)

const routes = [
  {
    path: "/",

    component: HomeLayout,
    children: [
      {
        name: "home",
        path: "",
        component: HomeView,
      },
    ],
  },
  {
    path: "/login",

    component: LoginLayout,
    children: [
      {
        name: "login",
        path: "",
        component: LoginView,
        meta: {
          title: "Accedi",
        },
      },
    ],
  },
  {
    path: "/register",

    component: LoginLayout,
    children: [
      {
        name: "register",
        path: "",
        component: RegisterView,
        meta: {
          title: "Registrati",
        },
      },
    ],
  },
  {
    path: "/projects",
   
    component: DashboardLayout,
    children: [
      {
        name: "projects",
        path: "",
        component: ProjectsView,
        meta: {
          title: "Esperienze",
        },
      },
    ],
  },
  {
    path: "/routes",
    
    component: DashboardLayout,
    children: [
      {
        name: "routes",
        path: "",
        component: RoutesView,
        meta: {
          title: "Percorsi",
        },
      },
    ],
  },
  {
    path: "/widgets",
    
    component: DashboardLayout,
    children: [
      {
        name: "widgets",
        path: "",
        component: WidgetsView,
        meta: {
          title: "Widget",
        },
      },
    ],
  },
  {
    path: "/stats",
    
    component: DashboardLayout,
    children: [
      {
        name: "stats",
        path: "",
        component: StatsView,
        meta: {
          title: "Statistiche",
        },
      },
    ],
  },
  {
    path: "/profile",
    
    component: DashboardLayout,
    children: [
      {
        name: "profile",
        path: "",
        component: ProfileView,
        meta: {
          title: "Profilo",
        },
      },
    ],
  },
  {
    path: "/useradmin",
    
    component: DashboardLayout,
    children: [
      {
        name: "useradmin",
        path: "",
        component: UseradminView,
        meta: {
          title: "Gestione utenti",
        },
      },
    ],
  },
  {
    path: "/configadmin",
    
    component: DashboardLayout,
    children: [
      {
        name: "configadmin",
        path: "",
        component: ConfigadminView,
        meta: {
          title: "Configurazione",
        },
      },
    ],
  },
  {
    path: "/help",
   
    component: DashboardLayout,
    children: [
      {
        name: "help",
        path: "",
        component: HelpView,
        meta: {
          title: "Supporto",
        },
      },
    ],
  },

  {
    path: "/project",
    component: ProjectLayout,
    children: [
      {
        path: "details/:id",
        name: "projectdetails",
        component: ProjectDetails,
        props: true,
        meta: {
          title: "projectdetails",
        },
      },
      {
        path: "app/:id",
        name: "projectapp",
        component: ProjectApp,
        props: true,
        meta: {
          title: "projectapp",
        },
      },
      {
        path: "interactive/:id",
        name: "projectinteractive",
        component: ProjectInteractive,
        props: true,
        meta: {
          title: "projectinteractive",
        },
      },
      {
        path: "deploy/:id",
        name: "projectdeploy",
        component: ProjectDeploy,
        props: true,
        meta: {
          title: "projectdeploy",
        },
      },
    ],
  },
  {
    path: "/project/app/",
    component: EditorLayout,
    children: [
      {
        path: "gallery/:index",
        name: "projectappgallery",
        props: true,
        component: EditorGallery,
        meta: {
          title: "projectappgallery",
        },
      },
      {
        path: "pano/:index",
        name: "projectapppano",
        props: true,
        component: EditorPano,
        meta: {
          title: "projectapppano",
        },
      },
      {
        path: "audio/:index",
        name: "projectappaudio",
        props: true,
        component: EditorAudio,
        meta: {
          title: "projectappaudio",
        },
      },
      {
        path: "video/:index",
        name: "projectappvideo",
        props: true,
        component: EditorVideo,
        meta: {
          title: "projectappvideo",
        },
      },
      {
        path: "quiz/:index",
        name: "projectappquiz",
        props: true,
        component: EditorQuiz,
        meta: {
          title: "projectappquiz",
        },
      },
      {
        path: "embed/:index",
        name: "projectappembed",
        props: true,
        component: EditorEmbed,
        meta: {
          title: "projectappembed",
        },
      },
    ],
  }
];

const router = new Router({
  routes
})


router.beforeEach(async (to, from, next) => {
  //const userStore = useUserStore(pinia)
  console.log(to.fullPath, from)
  next()
  
  /*if (to.fullPath == "/") {
    next()
  } else if (to.fullPath !== '/login' && !userStore.isLoggedIn) {
    next({ path: '/login' })
  } else next()
*/

})

export default router 