<template>
  <v-container>
    <vs-info-item-part  
      :openMediaEditor="openMediaEditor" 
      :item="item"
      :layout="6"
      :lang="projectStore.curLang"
    >
    </vs-info-item-part>

    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectMedia"
    ></vs-media-editor>

    <v-row  class="text-center">
      <v-col>
        <v-card>
          <v-card-title>Audio</v-card-title>
          <v-card-text>
            <audio :key="item.infoItemMediaItem[0]" :src="item.infoItemMediaItem[0]" controls></audio>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="my-0"
              color="primary"
              @click="openMediaEditor('audio', item)"
              >Seleziona
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- DEBUG -->
    <!-- code>{{ item }}</code -->
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";

export default {
  props: ["index"],
  name: "EditorAudio",
  mixins: [FormTheme],

  data: () => ({
    version: "1,0",
  }),
  computed: {
    item() {
      return this.projectStore.project.app.sections[this.index];
    },
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  mounted() {
    // Do something during load
  },
  methods: {
    openMediaEditor(resname, item) {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        { resname: resname, item: item }
      );
    },
    onSelectMedia(evn) {
      console.log("onSelectImage: ", evn);
      if (evn.options.resname == "audio") {
        if (evn.options.item.infoItemMediaItem.length <= 0) {
          evn.options.item.infoItemMediaItem.push("");
        }
        evn.options.item.infoItemMediaItem[0] = evn.url;
      } else if (evn.options.resname == "infoItemMainPicture") {
        this.item.infoItemPreviewPicture = evn.url;
        this.item.infoItemMainPicture = evn.url;
      } else {
        evn.options.item[evn.options.resname] = evn.url;
      }
    },
  },
};
</script>
