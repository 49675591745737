<template>
  <v-container>
    <v-row align="center" justify="center">
      <v-col cols="6">
        <v-card>
          <v-card-title> Informazioni Personali </v-card-title>
          <v-card-text>
            <v-text-field
              :dense="form.dense"
              :outlined="form.outlined"
              v-model="userStore.userprofile.email"
              label="EMail"
              readonly
            ></v-text-field>

            <v-text-field
              :dense="form.dense"
              :outlined="form.outlined"
              v-model="userStore.userprofile.username"
              label="Nome utente"
              required
            ></v-text-field>

            <v-text-field
              :dense="form.dense"
              :outlined="form.outlined"
              v-model="userStore.userprofile.name"
              label="Nome"
              required
            ></v-text-field>

            <v-text-field
              :dense="form.dense"
              :outlined="form.outlined"
              v-model="userStore.userprofile.surname"
              label="Cognome"
              required
            ></v-text-field>

            <h2
              v-if="userStore.userprofile.groups.length > 0"
              class="subtitle-1 mb-1"
            >
              Gruppi
            </h2>
            <v-chip-group column>
              <v-chip
                color="secondary"
                v-for="group in userStore.userprofile.groups"
                :key="group"
              >
                {{ group.label }}
              </v-chip>
            </v-chip-group>

            <h2
              v-if="userStore.userprofile.roles.length > 0"
              class="subtitle-1 mb-1"
            >
              Ruoli
            </h2>
            <v-chip-group column>
              <v-chip v-for="role in userStore.userprofile.roles" :key="role.id">
                {{ role.label }}
              </v-chip>
            </v-chip-group>

            <!-- v-textarea
              :dense="form.dense"
              :outlined="form.outlined"
              v-model="userStore.userprofile.notes"
              label="Note"
              required
            ></v-textarea -->
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn :loading="userStore.loading" color="primary" @click="userStore.updateProfile()"
              >Aggiorna Informazioni</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";

import FormTheme from "@/mixins/formtheme";
import HtmlComponent from "@/mixins/htmlcomponent";

export default {
  components: {
    ConfirmDlg: () => import("@/components/ui/ConfirmDlg"),
  },
  mixins: [FormTheme, HtmlComponent],
  name: "ProfileView",
  computed: {
    ...mapStores(useUserStore, useConfigStore),
  },
  data: () => ({
    version: "1,0",
  }),
  mounted() {
    // placeholder
  },
  methods: {
    updateProfile() {
      //   userStore.updateProfile()
    },
  },
};
</script>
