<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-title>
            <h5>Gestione Utenti</h5>
          </v-card-title>
          <v-card-title>
            <v-text-field
              v-model="search"
              append-icon="mdi-magnify"
              label="Search"
              single-line
              hide-details
            ></v-text-field>
          </v-card-title>
          <v-data-table
            :headers="headers"
            :items="userStore.users"
            :search="search"
          >
            <template v-slot:top>
              <v-dialog v-model="dialog" max-width="500px">
                <v-card>
                  <v-card-title>
                    <span class="text-h5">Gestione Utente</span>
                  </v-card-title>
                  <v-card-subtitle>
                    <span class="text-h6">{{ editedItem.email }}</span>
                  </v-card-subtitle>
                  <v-card-text>
                    <v-container>
                      <v-row>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.groups"
                            :items="userStore.userGroups"
                            return-object
                            outlined
                            chips
                            label="Gruppi"
                            multiple
                            item-text="label"
                            item-value="id"
                          ></v-autocomplete>
                        </v-col>
                        <v-col cols="12">
                          <v-autocomplete
                            v-model="editedItem.roles"
                            :items="roleList"
                            outlined
                            return-object
                            chips
                            label="Ruoli"
                            multiple
                            item-text="label"
                            item-value="id"
                          ></v-autocomplete>
                        </v-col>
                      </v-row>
                    </v-container>
                  </v-card-text>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="blue darken-1" text @click="close">
                      Annulla
                    </v-btn>
                    <v-btn color="blue darken-1" text @click="save">
                      Conferma
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>

            <template v-slot:[`item.roles`]="{ item }">
              <v-chip-group column>
                <v-chip v-for="role in item.roles" :key="role.id">
                  {{ role.label }}
                </v-chip>
              </v-chip-group>
            </template>

            <template v-slot:[`item.groups`]="{ item }">
              <v-chip-group column>
                <v-chip
                  color="secondary"
                  v-for="group in item.groups"
                  :key="group"
                >
                  {{ group.label }}
                </v-chip>
              </v-chip-group>
            </template>

            <template v-slot:[`item.actions`]="{ item }">
              <v-icon small class="mr-2" @click="editItem(item)">
                mdi-pencil
              </v-icon>
            </template>
          </v-data-table>
        </v-card>
      </v-col>
    </v-row>
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import FormTheme from "../mixins/formtheme"; // TODO vedere come è meglio strutturarlo

import { mapStores } from "pinia";

import { useConfigStore } from "@/stores/ConfigStore";

import { useUserStore } from "@/stores/UserStore";

export default {
  name: "UseradminView",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
    search: "",
    dialog: false,
    values: [],
    headers: [
      { text: "EMail", value: "email" },
      { text: "Nome", value: "username" },
      { text: "Ruoli", value: "roles" },
      { text: "Gruppi", value: "groups" },
      { text: "", value: "actions" },
    ],
    roleList: [
      { label: "Amministratore", id: "admin" },
      { id: "editor", label: "Editore" },
    ],
    editedItem: {
      email: "",
      roles: [],
      groups: [],
    },
    newgroup: "",
    items: [],
  }),
  mounted() {
    this.userStore.loadUsers();
    this.userStore.loadGroups();
  },
  components: {
    ConfirmDlg: () => import("../components/ui/ConfirmDlg"),
  },
  computed: {
    ...mapStores(useConfigStore, useUserStore),
  },
  methods: {
    editItem(item) {
      this.prevItem = JSON.parse(JSON.stringify(item));
      this.editedItem = item;
      this.dialog = true;
    },
    close() {
      this.editedItem = this.prevItem;
      this.dialog = false;
    },
    save() {
      this.userStore.updateUserProfile(this.editedItem);
      this.dialog = false;
    },
    removeGroup(group) {
      for (var i = 0; i < this.editedItem.groups.length; i++) {
        if (group == this.editedItem.groups[i]) {
          this.editedItem.groups.splice(i);
        }
      }
    },
    addGroup() {
      if (this.editedItem.groups.indexOf(this.newgroup) < 0) {
        this.editedItem.groups.push(this.newtag);
        this.newgroup = "";
      }
    },
  },
};
</script>
