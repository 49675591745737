<template>
  <v-container>
    <v-row v-if="projectStore.loading">
      <v-col>
        <v-progress-circular
          :size="50"
          color="primary"
          indeterminate
        ></v-progress-circular>
      </v-col>
    </v-row>

    <v-form v-model="valid" v-if="projectStore.loading == false">
      <v-row :dense="form.dense">
        <v-col :dense="form.dense" cols="6">
          <!-- v-text-field
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="project.vorder"
            label="View Order"
            required
          ></v-text-field -->
          <!-- v-text-field
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="project.beaconmajmin"
            label="Beacon Maj Min (ex. 4-23551)"
            required
          ></v-text-field -->

          <v-card hover>
            <l-map
              style="height: 250px"
              :zoom.sync="projectStore.project.location.map.zoom"
              :center.sync="projectStore.project.location.map.center"
            >
              <l-tile-layer
                :url="configStore.map.url"
                :attribution="configStore.map.attribution"
              ></l-tile-layer>
              <l-marker
                :lat-lng.sync="projectStore.project.location.latLng"
                draggable
              ></l-marker>
            </l-map>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="openMapEditor"
                >Modifica Posizione</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
        <v-col :dense="form.dense" cols="6">
          <v-card hover>
            <v-img
              @click="openMediaEditor"
              max-height="250"
              contain
              :src="$imagekit.resize(projectStore.project.img, 800)"
            ></v-img>

            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="openMediaEditor"
                >Seleziona immagine</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
          <!-- v-img @click="openMediaEditor" contain :src="projectStore.project.img" ></v-img -->

          <!--v-btn @click="openMediaEditor">{{projectStore.project.img}}</v-btn -->
        </v-col>
      </v-row>
      <v-row :dense="form.dense">
        <v-col cols="12">
          <v-text-field
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.title"
            label="Titolo progetto"
            required
          ></v-text-field>
          <v-text-field
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.subtitle"
            label="Sottotitolo progetto"
            required
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row :dense="form.dense">
        <v-col cols="12">
          <v-textarea
            :counter="form.counter"
            :auto-grow="form.autogrow"
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.descpub"
            label="Descrizione pubblica dell'esperienza"
          ></v-textarea>
        </v-col>
      </v-row>
      <v-row :dense="form.dense">
        <v-col cols="12">
          <v-textarea
            :auto-grow="form.autogrow"
            :counter="form.counter"
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.descfunct"
            label="Descrizione funzionale dell'esperienza"
          ></v-textarea>
        </v-col>
      </v-row>
      <!-- v-row :dense="form.dense">
        <v-col cols="12">
          <v-text-field
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.video"
            label="Inserisci url youtube o vimeo"
          ></v-text-field>
        </v-col>
      </v-row -->
      <v-row :dense="form.dense">
        <v-col cols="12">Stagionalità</v-col>
        <v-col cols="3">
          <v-checkbox
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.season.spring"
            label="Primavera"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.season.summer"
            label="Estate"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.season.autumn"
            label="Autunno"
          ></v-checkbox>
        </v-col>
        <v-col cols="3">
          <v-checkbox
            :dense="form.dense"
            :outlined="form.outlined"
            v-model="projectStore.project.season.winter"
            label="Inverno"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row :dense="form.dense">
        <v-col cols="6">
          <v-select
            :outlined="form.outlined"
            :dense="form.dense"
            v-model="projectStore.project.typology"
            :items="configStore.typologyList"
            label="Tipologia"
          ></v-select>
        </v-col>
        <v-col cols="6">
          <v-checkbox
            :outlined="form.outlined"
            :dense="form.dense"
            v-model="projectStore.project.disabled"
            label="Accessibile ai disabili"
          ></v-checkbox>
        </v-col>
      </v-row>
      <v-row :dense="form.dense">
        <v-col cols="4">
          <v-select
            :outlined="form.outlined"
            :dense="form.dense"
            v-model="projectStore.project.minduration"
            :items="configStore.mindurationList"
            label="Durata"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            :outlined="form.outlined"
            :dense="form.dense"
            v-model="projectStore.project.theme"
            :items="configStore.themeList"
            label="Tematica"
          ></v-select>
        </v-col>
        <v-col cols="4">
          <v-select
            :outlined="form.outlined"
            :dense="form.dense"
            v-model="projectStore.project.audience"
            :items="configStore.audienceList"
            label="Pubblico di riferimento"
          ></v-select>
        </v-col>
      </v-row>

      <v-row :dense="form.dense">
        <v-col cols="2">
          <v-tooltip v-model="showTagTooltip" top>
            <template v-slot:activator="{ on, attrs }">
              <!-- v-btn icon v-bind="attrs" v-on="on">
                <v-icon color="primary">mdi-tag </v-icon>
              </v-btn -->
              <v-text-field
                v-bind="attrs"
                v-on="on"
                v-on:keyup.enter="addTag"
                :outlined="form.outlined"
                :dense="form.dense"
                hint="Tagga la tua esperienza"
                persistent-hint
                v-model="newtag"
                append-icon="mdi-plus"
                label="Aggiungi TAG"
                type="text"
                @click:append="addTag"
              ></v-text-field>
            </template>
            <span>Inserire un valore per il TAG e premere +</span>
          </v-tooltip>
        </v-col>
        <v-col cols="10">
          <v-chip-group active-class="primary--text" column>
            <v-chip
              v-for="tag in projectStore.project.tags"
              color="primary"
              close
              :key="tag"
              @click:close="removeTag(tag)"
            >
              {{ tag }}
            </v-chip>
          </v-chip-group>
        </v-col>
      </v-row>
    </v-form>
    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectImage"
    ></vs-media-editor>
  </v-container>
</template>

<script>
import { mapStores } from "pinia";
import { mapActions } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";
export default {
  props: ["id"],
  name: "ProjectDetails",
  mixins: [FormTheme],
  data: () => ({
    newtag: "",
    showTagTooltip: false,
    valid: true,
  }),
  mounted() {
    console.log("ProjectDetails", this.id);
    this.projectStore.loadProject(this.id);
  },
  computed: {
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
    ...mapActions(useProjectStore, ["updateProject"]),
  },
  methods: {
    openMediaEditor() {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        {}
      );
    },
    onSelectImage(evn) {
      console.log("onSelectImage: ", evn);
      this.projectStore.project.img = evn.url;
    },
    openMapEditor() {
      // this.$refs.mapeditor.open(this.getCurGroup, this.project.img, {});
    },
    addTag() {
      if (this.newtag == "") {
        this.showTagTooltip = true;
      } else {
        this.showTagTooltip = false;
        if (this.projectStore.project.tags.indexOf(this.newtag) < 0) {
          this.projectStore.project.tags.push(this.newtag);
          this.newtag = "";
        }
      }
    },
    removeTag(tag) {
      for (var i = 0; i < this.projectStore.project.tags.length; i++) {
        if (tag == this.projectStore.project.tags[i]) {
          this.projectStore.project.tags.splice(i,1);
          return;
        }
      }
      console.log("removeTag", tag);
    },
  },
};
</script>
