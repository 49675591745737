 <template>
  <v-row class="my-3 text-center">
    <!-- v-col :cols="layout/2">
      <v-card class="mx-auto">
        <span>Immagine in lista</span>
        <v-img height="150" :src="item.infoItemPreviewPicture">
          
        </v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="my-0"
            color="primary"
            @click="openMediaEditor('infoItemPreviewPicture', item)"
            >Seleziona
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col -->
    <v-col :cols="layout">
      <v-card class="mx-auto">
        <v-img
          height="150"
          contain
          :src="$imagekit.resize(item.infoItemMainPicture, 300)"
        >
        </v-img>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            text
            class="my-0"
            color="primary"
            @click="openMediaEditor('infoItemMainPicture', item)"
            >Seleziona Immagine
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-col>
    <v-col :cols="layout">
      <v-card class="mx-auto">
        <v-card-text> 
          <v-text-field
            dense
            label="Titolo"
            v-model="$trlang.tr(item, lang).infoItemTitle"
          >
            <template v-slot:append-outer>
              <vs-lang-button :lang="lang"></vs-lang-button>
            </template>
          </v-text-field>

          <v-text-field
            dense
            label="Sottotitolo"
            v-model="$trlang.tr(item, lang).infoItemSubTitle"
          >
          <template v-slot:append-outer>
              <vs-lang-button :lang="lang"></vs-lang-button>
            </template>
          </v-text-field>
          <v-textarea
            dense
            auto-grow
            rows="1"
            label="Descrizione"
            v-model="$trlang.tr(item, lang).infoItemDescription"
          ><template v-slot:append-outer>
              <vs-lang-button :lang="lang"></vs-lang-button>
            </template>
          </v-textarea>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
    
<script>
module.exports = {
  props: {
    item: Object,
    layout: Number,
    openMediaEditor: Function,
    lang: String,
  },
  getters: {
    getlang() {
      if (this.lang) {
        return this.lang;
      } else {
        return "it";
      }
    },
  },
};
</script>