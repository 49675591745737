import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import it from 'vuetify/lib/locale/it';
import en from 'vuetify/lib/locale/en';

Vue.use(Vuetify);

export default new Vuetify({
    lang: {
        locales: {
            it,
            en,
          },
        current: 'it' // en | es | fr | pl | ru | uk | ptbr | tr | he | nl | ja | de | ko | zhHans | fa | sv | cs | it | el
    }
});
