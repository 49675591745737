<template>
  <v-container>
   
     <vs-info-item-part  
      :openMediaEditor="openMediaEditor" 
      :item="item"
      :layout="6"
      :lang="projectStore.curLang"
      >
    </vs-info-item-part>

    <v-row>
      <v-col cols="6">
        <v-textarea
          name="input-infoItemEmbed"
          label="HTML"
          hint="Inserisci il contenuto per Embed HTML"
          v-model="item.infoItemEmbed"
        ></v-textarea>
      </v-col>
      <v-col cols="6">
        <!-- v-btn @click="loadEmbed()">Anteprima</v-btn -->
        <iframe :srcdoc="item.infoItemEmbed" ></iframe>
        <!-- code>{{ item }}</code -->

        <!-- div v-html="item.infoItemEmber"></div -->
      </v-col>
    </v-row>
    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectImage"
    ></vs-media-editor>
  </v-container>
</template>
<style scoped>
iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  border: none;
}
</style>
<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";

export default {
  props: ["index"],
  name: "EditorEmbed",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
    htmlDoc: "",
  }),
  computed: {
    item() {
      return this.projectStore.project.app.sections[this.index];
    },
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  mounted() {
    // Do something during load
  },
  methods: {
    openMediaEditor(resname) {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        { resname: resname }
      );
    },
    onSelectImage(evn) {
      if (evn.options.resname == "infoItemMainPicture") {
        this.item.infoItemPreviewPicture = evn.url;
        this.item.infoItemMainPicture = evn.url;
      } else {
        this.item[evn.options.resname] = evn.url;
      }
      
    },
    loadEmbed() {
      this.htmlDoc = ```<!DOCTYPE html>
            <html>
              <head>
                <title>Incorpora Contenuto</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
              </head>
              <body>
              <style>
                * {
                  margin: 0;
                  padding: 0;
                }
                html, 
                body {
                    height: 100%;
                }
                iframe {
                  aspect-ratio: 1 / 1;
                  height: 100%;
                  width: 100%;
                }
              </style>
                ${this.item.infoItemEmbed}
              </body>
            </html>```;
      //Method placeholder
    },
  },
};
</script>
