import { getAuth, signInWithEmailAndPassword, createUserWithEmailAndPassword, signOut, sendPasswordResetEmail, EmailAuthProvider } from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { collection, doc, addDoc, setDoc, getDoc, getDocs, query } from "firebase/firestore";

// TODO creare globale nel main ...
import router from "../router";
import { defineStore } from 'pinia'
import { useUiStore } from './UiStore'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUserStore = defineStore({
    id: 'user',
    // state
    state: () => {
        return {
            loading: false,
            isLoggedIn: false,
            version: 1,
            user: {
                'email': ''
            },
            userprofile: {
                username: 'username',
                email: 'email',
                name: 'name',
                surname: 'surname',
                notes: 'notes',
                roles: [],
                groups: []
            },
            userGroups: [],
            users: [],
            error: '',
            messageSent: '',
            requestSent: false,
            asyncWaiting: false,
            firebaseErrors: {
                'auth/user-not-found': 'Nessun utente trovato con questa email',
                'auth/wrong-password': 'Password non valida',
                'auth/email-already-in-use': 'Questo indirizzo email è già presente nel sistema',
            },
            curGroup: '',
        }
    },
    actions: {
        addMail(payload) {
            this.addMailAsync(payload)
        },
        async addMailAsync(payload) {
            const db = getFirestore(this.$fbApp);
            //const uiStore = useUiStore();
            try {
                await addDoc(
                    collection(db, "mail"),
                    payload
                );
            } catch (e) {
                console.error("Error adding document: ", e);
            }

            /*uiStore.updateSnackbar({
                show: true,
                message: "La richiesta è stata inviata correttamente.",
            });*/

            this.requestSent = true;

        },
        async loadProfileAsync() {
            console.log("**************** loadProfileAsync");
            const vms = this;
            this.loading = true;
            const db = getFirestore(this.$fbApp);
            const docRef = doc(db, "users", vms.user.uid)
            const docSnap = await getDoc(docRef);
            if (docSnap.exists()) {
                vms.userprofile = docSnap.data();
                console.log("vms.userprofile", vms.userprofile);
                if (this.curGroup == "") {
                    this.curGroup = vms.user.uid;
                }
            } else {
                vms.userprofile = {
                    username: vms.user.username,
                    email: vms.user.email,
                    name: vms.user.username,
                    surname: vms.user.username,
                    notes: '',
                    roles: [],
                    groups: []
                };
                console.log("loadProjectAsync No such document!");
            }

            this.loading = false;
        },
        loadUsers() {
            this.loadUsersAsync();
        },
        async loadUsersAsync() {
            const vms = this;
            const db = getFirestore(this.$fbApp);
            const q = query(collection(db, "users"));
            const querySnapshot = await getDocs(q);
            vms.users = [];

            querySnapshot.forEach((doc) => {
                var docData = doc.data();
                docData['id'] = doc.id;
                vms.users.push(docData);
            });

        },
        loadGroups() {
            this.loadGroupsAsync();
        },
        async loadGroupsAsync() {
            const vms = this;
            const db = getFirestore(this.$fbApp);
            const q = query(collection(db, "groups"));
            const querySnapshot = await getDocs(q);
            vms.userGroups = [];

            querySnapshot.forEach((doc) => {
                var docData = doc.data();
                docData['id'] = doc.id;
                vms.userGroups.push(docData);
            });

        },
        addNewGroup(payload) {
            this.addNewGroupAsync(payload)
        },
        async addNewGroupAsync(payload) {
            const db = getFirestore(this.$fbApp);
            const uiStore = useUiStore();
            try {
                await addDoc(
                    collection(db, "groups"),
                    {
                        "label": payload,
                        "name": payload
                    }
                );

                this.loadGroupsAsync();
            } catch (e) {
                console.error("Error adding document: ", e);
            }

            uiStore.updateSnackbar({
                show: true,
                message: "Il gruppo è stato aggiunto correttamente.",
            });
            

        },
        updateUserProfile(newprofile) {
            const uiStore = useUiStore();
            const db = getFirestore(this.$fbApp);
            this.error = '';
            setDoc(doc(db, "users", newprofile.id), newprofile);
            uiStore.updateSnackbar({
                show: true,
                message: "I dati sono stati aggiornati correttamente.",
            });
        },
        updateProfile() {
            const vms = this;
            const uiStore = useUiStore();
            const db = getFirestore(this.$fbApp);
            this.error = '';
            setDoc(doc(db, "users", vms.user.uid), vms.userprofile);
            uiStore.updateSnackbar({
                show: true,
                message: "I dati sono stati aggiornati correttamente.",
            });
        },
        signup(payload) {
            const vms = this;
            //const sui = useUiStore();
            const auth = getAuth(this.$fbApp);
            this.error = '';
            createUserWithEmailAndPassword(auth, payload.email, payload.password)
                .then(userCredential => {
                    vms.user = userCredential.user;
                    vms.userprofile = {
                        username: 'nome utente',
                        email: userCredential.user.email,
                        name: 'nome',
                        surname: 'cognome',
                        notes: '',
                        roles: [],
                        groups: []
                    };
                    vms.updateProfile();
                    console.log(JSON.stringify(userCredential.user, null, 2));
                    router.push("/projects");
                })
                .catch((error) => {
                    vms.user = { 'email': '' };
                    const errorCode = error.code;
                    var errorMessage = error.message;

                    if (typeof (vms.firebaseErrors[error.code]) != 'undefined') {
                        errorMessage = vms.firebaseErrors[error.code];
                    }
                    this.error = errorMessage;
                    /*sui.updateSnackbar({
                        show: true,
                        message: errorMessage,
                    });*/
                    console.log("createUserWithEmailAndPassword errorCode: ", errorCode);
                });

        },
        login(payload) {
            const vms = this;
            const auth = getAuth(this.$fbApp);
            this.error = '';
            this.asyncWaiting = true;
            signInWithEmailAndPassword(auth, payload.email, payload.password)
                .then((userCredential) => {
                    vms.user = userCredential.user;
                    vms.loadProfileAsync();
                    router.push("/projects");
                }).finally(() => {
                    this.asyncWaiting = false;
                }).catch((error) => {
                    vms.user = { 'email': '' };
                    var errorMessage = error.message;
                    if (typeof (vms.firebaseErrors[error.code]) != 'undefined') {
                        errorMessage = vms.firebaseErrors[error.code];
                    }
                    this.error = errorMessage;
                });
        },
        logout() {
            const vms = this;
            const auth = getAuth(this.$fbApp);
            this.asyncWaiting = true;
            signOut(auth)
                .then(() => {
                    vms.user = { 'email': '' };
                    router.push("/");
                }).finally(() => {
                    console.log('finally...');
                    this.asyncWaiting = false;
                }).catch((error) => {
                    vms.user = { 'email': '' };
                    router.push("/");
                    console.log(error);
                });
        },
        forgetPassword(payload) {
            /*
const actionCodeSettings = {
  url: 'https://www.example.com/?email=user@example.com',
  iOS: {
     bundleId: 'com.example.ios'
  },
  android: {
    packageName: 'com.example.android',
    installApp: true,
    minimumVersion: '12'
  },
  handleCodeInApp: true
};
await sendPasswordResetEmail(auth, 'user@example.com', actionCodeSettings);
            */
            const vms = this;
            const auth = getAuth(this.$fbApp);
            this.asyncWaiting = true;
            sendPasswordResetEmail(auth, payload.email)
                .then(() => {
                    vms.messageSent = 'Controlla la tua email e segui le indicazioni per reimpostare la password! Se non hai ricevuto l\'email verifica anche nello SPAM',
                        vms.user = { 'email': '' };
                }).finally(() => {
                    this.asyncWaiting = false;
                }).catch(() => {
                    vms.error = "Errore ripristino password, contattare l'amministratore di sistema.";
                })
        },

        reauthenticate(currentPassword) {
            const auth = getAuth(this.$fbApp);
            var user = auth.currentUser;
            var cred = EmailAuthProvider.credential(
                user.email, currentPassword);
            return user.reauthenticateWithCredential(cred);
        },

        changePassword(payload) {
            const vms = this;
            const auth = getAuth(this.$fbApp);
            const currentPassword = payload.currentPassword;
            const newPassword = payload.newPassword;
            this.reauthenticate(currentPassword).then(() => {
                const user = auth.currentUser;
                user.updatePassword(newPassword).then(() => {
                    // TODO: write a message to the user
                }).catch((error) => {
                    console.log(error);
                    vms.error = error.message;
                });
            }).catch((error) => {
                console.log(error);
                vms.error = error.message;
            });
        }
    },
    getters: {
        getUserGroups: (state) => {
            var userGroups = [];
            userGroups.push({
                id: state.user.uid,
                label: 'personale',
            });
            for (var i = 0; i < state.userprofile.groups.length; i++) {
                userGroups.push(state.userprofile.groups[i]);
            }
            return userGroups;
        },
        getGroupNameById: (state) => {
            return (groupId) => {
                const group = state.userprofile.groups.find((group) => group.id === groupId);
                if (group) {
                    return group['label']
                } else {
                    return "personale";
                }


            }
        },
        hasUserRole: (state) => {
            return (roleId) => {
                if (state.userprofile.roles.find((role) => role.id === roleId)) {
                    return true
                } else {
                    return false
                }
            }
        },

    }
})