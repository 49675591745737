<template>
  <v-container>
    <v-row v-if="projectStore.project">
      <v-col cols="12">
        <h3>{{ projectStore.project.interaction.model }}</h3>
      </v-col>

      <v-col
        v-if="projectStore.project.interaction.sections.length == 0"
        cols="12"
      >
        <v-alert type="info"
          >Se vuoi aggiungere una postazione interattiva clicca sul pulsante
          aggiungi postazione interattiva</v-alert
        >

        <v-menu offset-y>
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="primary" v-bind="attrs" v-on="on">
              Aggiungi Postazione Interattiva
            </v-btn>
          </template>
          <v-list>
            <v-list-item
              @click="
                projectStore.addInteractiveSection(interactiontype.modeltype)
              "
              v-for="(interactiontype, index) in configStore.interactiontypes"
              :key="index"
            >
              <v-list-item-title
                >{{ interactiontype.title }} -
                {{ interactiontype.modeltype }}</v-list-item-title
              >
            </v-list-item>
          </v-list>
        </v-menu>
      </v-col>

      <v-col
        v-if="projectStore.project.interaction.sections.length > 0"
        cols="12"
      >
        <vs-int-smartboxaudiosa
          v-if="projectStore.project.interaction.model == 'smartboxaudiosa'"
          :openMediaEditor="openMediaEditor"
          :item="projectStore.project.interaction.sections[0]"
          :layout="6"
          :lang="projectStore.curLang"
        >
        </vs-int-smartboxaudiosa>

        <vs-int-smartboxrfid5
          v-if="projectStore.project.interaction.model == 'smartboxrfid5'"
          :openMediaEditor="openMediaEditor"
          :item="projectStore.project.interaction.sections[0]"
          :layout="6"
          :lang="projectStore.curLang"
        >
        </vs-int-smartboxrfid5>

        <!-- {{ projectStore.project.interaction }} -->
      </v-col>
    </v-row>
    <v-row class="my-4 text-center">
      <v-col>
        <v-btn color="secondary" @click="removeInteractiveSection()"
          >Rimuovi Postazione Interattiva</v-btn
        >
      </v-col>
    </v-row>

    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectMedia"
    ></vs-media-editor>

    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";

export default {
  components: {
    ConfirmDlg: () => import("../../components/ui/ConfirmDlg"),
  },
  props: ["id"],
  name: "ProjectInteractive",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
    itemJson: "",
  }),
  mounted() {
    console.log("id", this.id);
    this.projectStore.loadProject(this.id);
  },
  computed: {
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  methods: {
    async removeInteractiveSection() {
      if (
        await this.$refs.confirm.open(
          "Conferma",
          "Sei sicuro di voler rimuovere l'interazione ? Se l'interazione è già pubblicata potrebbero esserci problemi sulle postazioni interattive già installate.  "
        )
      ) {
        this.projectStore.project.interaction.sections = [];
      }
    },
    openMediaEditor(resname, item) {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        { resname: resname, item: item }
      );
    },
    onSelectMedia(evn) {
      console.log("onSelectMedia: ", JSON.stringify(evn, null, 2));
      evn.options.item[evn.options.resname] = evn.url;
      if (evn.options.resname == "remoteurl") {
        evn.options.item["remoteurl"] = evn.url;
        evn.options.item["url"] = evn.url;
        evn.options.item["id"] = evn.name;
        evn.options.item["title"] = evn.name;
      } else {
        evn.options.item[evn.options.resname] = evn.url;
        evn.options.item["medianame"] = evn.name;
        evn.options.item["mediameta"] = evn.metadata.contentType;
      }
    },
  },
};
</script>
