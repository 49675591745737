<template>
  <v-container>
    <v-row class="text-center">
      <v-col cols="6">
        <v-card>
          <v-card-title>{{ this.projectStore.project.title }}</v-card-title>
          <v-card-text>
            <v-alert type="info"
              >Per scaricare il QR Code, posizionarsi con il mouse sul QRCode,
              <br />
              click tasto destro, "Salva immagine con nome"</v-alert
            >
            <qrcode-vue
              :value="getQRCodeURl()"
              :size="size"
              level="H"
            ></qrcode-vue>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <a target="_blank" :href="getQRCodeURl()">{{ getQRCodeURl() }}</a>
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-text>
            <v-alert type="success">
              Utilizzando il QRCode all'interno dell'App è possibile
              visualizzare l'esperienza, anche se non ancora pubblicata.
            </v-alert>
          </v-card-text>
        </v-card>

        <v-card class="my-3">
          <v-card-title>Widget Esperienza</v-card-title>
          <v-card-text>
            <v-row>
              <v-col>
                <v-select
                  :items="langs"
                  item-text="label"
                  item-value="id"
                  v-model="lng"
                  dense
                  label="Lingua"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-btn class="mx-3" @click="generaCodice" color="primary">
                  Genera Codice
                </v-btn>
              </v-col>
            </v-row>
            <v-row v-if="gencode != ''">
              <v-col>
                <v-card>
                  <v-card-title>Codice widget </v-card-title>
                  <p>
                    Utilizza il seguente codice per pubblicare l'esperienza sul
                    proprio sito
                  </p>
                  <v-card-text style="text-align: left">
                    <pre><code>{{gencode}}</code></pre>
                  </v-card-text>
                </v-card>
              </v-col>
            </v-row>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>

            <div class="text-center">
              <v-dialog v-model="dialog" width="60%">
                <template v-slot:activator="{ on, attrs }">
                  <v-btn v-show="gencode != ''" color="primary" dark v-bind="attrs" v-on="on">
                    Visualizza anteprima
                  </v-btn>
                </template>

                <v-card>
                  <v-card-title class="text-h5 grey lighten-2">
                    Anteprima Widget
                  </v-card-title>

                  <v-card-text>
                   <iframe width="100%" height="400" :srcdoc="gencode"></iframe>
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="dialog = false">
                      Chiudi
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </div>

           
            <v-spacer></v-spacer>
          </v-card-actions>
          <v-card-text> </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card>
          <v-card-title>Pubblicazione esperienza</v-card-title>

          <v-card-text>
            <v-alert
              type="info"
              v-if="this.projectStore.project.deploystatus == 0"
            >
              Per rendere visibile l'esperienza sull'App <br />clicca sul
              pulsante Pubblica.</v-alert
            >
            <v-alert
              type="success"
              v-if="this.projectStore.project.deploystatus == 1"
            >
              Per rendere l'esperienza non visibile <br />clicca sul pulsante
              Annulla pubblicazione.</v-alert
            >
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              v-if="this.projectStore.project.deploystatus == 0"
              @click="deployProject()"
              color="primary"
              >Pubblica</v-btn
            >
            <v-btn
              v-if="this.projectStore.project.deploystatus == 1"
              @click="undeployProject()"
              color="secondary"
              >Annulla pubblicazione</v-btn
            >
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>

        <v-card class="mt-3">
          <v-card-title>Assegnazione Beacon</v-card-title>

          <v-card-text v-if="projectStore.project.beaconmajmin == ''">
            <v-alert type="info">
              Per aggiungere un Beacon con cui notificare l'app, <br />inserisci
              il codice del beacon che trovi stampigliato sul contenitore.
              <br />Es: SN: 04/023501 --> Inserire come codice
              <b>04-023501</b></v-alert
            >
          </v-card-text>
          <v-card-text>
            <v-text-field
              outlined
              dense
              label="Codice Beacon (nel formato: XX-XXXXXX)"
              v-model="projectStore.project.beaconmajmin"
            ></v-text-field>
          </v-card-text>
          <!--v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="projectStore.project.beaconmajmin !== ''" @click="updateBeaconValue()" color="primary" >Aggiungi Beacon</v-btn>
            
            <v-spacer></v-spacer>
          </v-card-actions -->
        </v-card>

        <v-card class="mt-3">
          <v-card-title>Codice esperienza interattiva</v-card-title>

          <v-card-text
            v-if="projectStore.project.interaction.sections.length <= 0"
          >
            <v-alert type="info">
              Se è stata aggiunta una postazione interattiva nella sezione
              "Interattività", verrà visualizzato il codice dell'esperienza da
              inserire nella configurazione del dispositivo interattivo</v-alert
            >
          </v-card-text>
          <v-card-text
            v-if="projectStore.project.interaction.sections.length > 0"
          >
            <v-alert type="info"
              >Codice della postazione interattiva <br />da inserire nella
              configurazione: <br />
              <h1 class="ma-3">{{ projectStore.project.interaction.id }}</h1>
            </v-alert>
          </v-card-text>
          <!--v-card-actions>
            <v-spacer></v-spacer>
            <v-btn v-if="projectStore.project.beaconmajmin !== ''" @click="updateBeaconValue()" color="primary" >Aggiungi Beacon</v-btn>
            
            <v-spacer></v-spacer>
          </v-card-actions -->
        </v-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
code {
  padding: 0;
  color: inherit;
  white-space: pre;
  white-space: pre-wrap;
  background-color: transparent !important;
  border: 0;
}
</style>
<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";
import QrcodeVue from "qrcode.vue";

export default {
  props: ["id"],
  name: "ProjectDeploy",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
    size: 300,
    langs: [
      { label: "Italiano", id: "it" },
      { label: "Inglese", id: "en" },
    ],
    lng: "it",
    gencode: "",
    dialog: false,
  }),
  components: {
    QrcodeVue,
  },
  computed: {
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  mounted() {
    console.log("ProjectDeploy", this.id);
    this.projectStore.loadProject(this.id);
  },
  methods: {
    getQRCodeURl() {
      return "https://phygo.io/noisplus?expid=" + this.id;
    },
    deployProject() {
      this.projectStore.project.deploystatus = 1;
      this.projectStore.updateProject();
    },
    undeployProject() {
      this.projectStore.project.deploystatus = 0;
      this.projectStore.updateProject();
    },
    updateBeaconValue() {
      //this.projectStore.project.beaconmajmin = this.beaconcode;
      this.projectStore.updateProject();
    },
    generaCodice() {
      // http://localhost:5002/smartcms-8d342/us-central1/app/widgets/pagejs/dddd/12345/it
      this.gencode =
        "<!-- START NOIS plus widget V 1.1 -->\n" +
        '<div id="npw' +
        this.projectStore.project.id +
        '"></div>\n' +
        '<script src="https://smartcms-8d342.web.app/api/widgets/pagejs/npw' +
        this.projectStore.project.id +
        "/" +
        this.projectStore.project.id +
        "/" +
        this.lng +
        '"><' +
        "/script>\n" +
        "<!-- START NOIS plus widget V 1.1 -->\n";
    },
  },
};
</script>
