<template>
  <v-container>

      <v-row justify="center">
        <v-col cols="12" class="text-center">
          <h1>Benvenuto nella piattaforma</h1>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="4">
          <v-img contain src="@/assets/nois-logo.png"></v-img>
        </v-col>
      </v-row>
      <v-row justify="center">
        <v-col cols="8">
          <p>Un progetto che vuole realizzare una nuova piattaforma hardware e software per operatori turistici per realizzare in autonomia, 
            senza competenze tecniche specialistiche, esperienze interattive immersive e sensoriali utilizzando le ultime tecnologie in ambito Internet delle Cose, 
            realtà aumentata o realtà virtuale.</p>
          <h2>Come iniziare?</h2>  
          <p>Registrati su questo portale e chiedi maggiori informazioni su come partecipare al progetto utilizzando questo link <a href="https://noisplus.it/community/">https://noisplus.it/community/</a>.</p>  
        </v-col>
      </v-row>

  
  
  </v-container>
</template>

<script>
export default {
  name: "HomeView",

  data: () => ({
    version: "1,0",
  }),
  mounted() {
    // Load all experiences (or the latest experiences ...)
  },
  methods: {
    example() {
      //Method placeholder
    },
  },
};
</script>
