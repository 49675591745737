<template>
  <span>{{ title }}</span>
</template>

<script>

// SOLO UN ESEMPIO PER TEST IMPORTAZIONE COMPONENTI

//import Field from "../../../mixins/field";
//import truncate from "lodash/truncate";

/**
 * Show value as simple text, render a simple span. HTML tags will be stripped.
 */
export default {
  props: ['title'],
  computed: {
    getText() {
      return this.value ;
      /*return this.truncate
        ? truncate(this.value, {
            length: this.truncate,
          })
        : this.value;*/
    },
  },
};
</script>