<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <v-flex xs12 sm8 md4>
        <!-- LOGIN -->
        <v-card v-if="forgotPassword == false" class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Accedi</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="gotoHome()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="mdi-account"
                v-model="email"
                label="EMail"
                type="text"
              ></v-text-field>
              <v-text-field
                id="password"
                prepend-icon="mdi-lock"
                v-model="password"
                label="Password"
                type="password"
              ></v-text-field>

              <v-checkbox
                id="rememberme"
                v-model="rememberme"
                label="Ricordami"
              ></v-checkbox>
            </v-form>
          </v-card-text>
          <v-card-actions>
            <v-btn block color="primary" @click="login()"
              ><v-icon class="mr-2">mdi-login</v-icon>Accedi</v-btn
            >
          </v-card-actions>
          <v-card-actions>
            <v-alert width="100%" v-show="userStore.error !== ''" type="error"
              >{{ userStore.error }}
            </v-alert>
          </v-card-actions>
          <v-card-actions>
            <v-btn text color="primary" @click="gotoForgotPassword(true)"
              >Password dimenticata ?</v-btn
            >
          </v-card-actions>
          <v-divider></v-divider>
          <!-- v-card-actions>
            <v-spacer></v-spacer>
            <v-btn @click="loginGoogle()"><v-icon>mdi-google</v-icon> Accedi con Google</v-btn>
            <v-spacer></v-spacer>
            <v-btn @click="loginFacebook()"><v-icon>mdi-facebook</v-icon> Accedi con Facebook</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions -->
          <v-card-actions>
            <v-btn block color="secondary" @click="newAccount()"
              ><v-icon class="mr-2">mdi-account-plus</v-icon>Crea nuovo
              account</v-btn
            >
          </v-card-actions>
        </v-card>

        <!-- forgotPassword -->
        <v-card  v-if="forgotPassword" class="elevation-12">
          <v-toolbar dark color="primary">
            <v-toolbar-title>Password dimenticata</v-toolbar-title>
            <v-spacer></v-spacer>
            <v-btn icon @click="gotoHome()">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-toolbar>
          <v-card-text>
            <v-form>
              <v-text-field
                prepend-icon="mdi-account"
                v-model="email"
                label="EMail"
                type="text"
              ></v-text-field>
            </v-form>
          </v-card-text>
          <v-card-actions v-show="userStore.messageSent == ''" >
            <v-btn :loading="userStore.asyncWaiting" block color="primary" @click="forgetPassword()"
              ><v-icon class="mr-2">mdi-reset</v-icon>Richiesta reset password</v-btn
            >
          </v-card-actions>
          <v-card-actions  v-show="userStore.error !== ''" >
            <v-alert width="100%" type="error"
              >{{ userStore.error }}
            </v-alert>
          </v-card-actions>
         <v-card-actions  v-show="userStore.messageSent !== ''" >
            <v-alert width="100%" type="success"
              >{{ userStore.messageSent }}
            </v-alert>
          </v-card-actions>
          <v-divider></v-divider>
          
          <v-card-actions>
            <v-btn block color="secondary" @click="gotoForgotPassword(false)"
              ><v-icon class="mr-2">mdi-login</v-icon>Torna alla pagina di Login</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";

export default {
  name: "LoginView",
  data: () => ({
    version: "1,0",
    email: "",
    password: "",
    rememberme: false,
    forgotPassword: false
  }),
  computed: {
    ...mapStores(useUserStore),
  },
  mounted() {
    if (localStorage.getItem("rememberme") == "true") {
      this.rememberme = true;
      this.email = localStorage.getItem("email");
      this.password = localStorage.getItem("password");
    }
    console.log("auth mounted");
  },
  methods: {
    login() {
      if (this.rememberme == true) {
        localStorage.setItem("rememberme", "true");
        localStorage.setItem("email", this.email);
        localStorage.setItem("password", this.password);
      } else {
        localStorage.setItem("rememberme", "false");
        localStorage.setItem("email", "");
        localStorage.setItem("password", "");
      }

      this.userStore.login({
        email: this.email,
        password: this.password,
      });
    },
    newAccount() {
      this.$router.push("register");
    },
    forgetPassword() {
      
      this.userStore.forgetPassword({
        email: this.email,
        password: this.password,
      });
    },
    gotoHome() {
      this.$router.push("/");
    },
    gotoForgotPassword(newvalue) {
      this.userStore.error = '';
      this.userStore.messageSent = '';
      this.forgotPassword = newvalue
    }
  },
};
</script>


