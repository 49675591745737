// https://ik.imagekit.io/vd5fu4nv7/noisplus/tr:w-300/o/Fv89EeP1IPEdOzPH6Qoi%2Fexp04%2Fexp04-w-silanus-aumentata.jpg?alt=media&token=7e9a381f-bfcc-4aee-b8ca-4d48cf8f9cdd



/*

/Users/dariocavada/Dropbox/Backup/progetti/NOIS-Photo

Install
in main.js

import {imagekit} from './functions/imagekit.js'
Vue.prototype.$imagekit = imagekit

*/

// Usage example $imagekit.resize(item.img, 300)


export const imagekit = {
    resize: (src, w, h) => {
        if (typeof (src) == "undefined") {
            return "";
        }
        try {


            if (src.indexOf("https://firebasestorage") < 0) {
                return src;
            }
        } catch (e) {
            return "";
        }

        var imgret = src.replace("https://firebasestorage.googleapis.com/v0/b/smartcms-8d342.appspot.com", "");
        if (typeof (h) == "undefined") {
            return "https://ik.imagekit.io/vd5fu4nv7/noisplus/tr:w-" + w + "/" + imgret;
        } else {
            return "https://ik.imagekit.io/vd5fu4nv7/noisplus/tr:w-" + w + ",h-" + h + "/" + imgret;
        }

    }
}