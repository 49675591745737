<template>
  <v-app>
    <v-app-bar app>
      <v-toolbar-title
        ><v-img
          contain
          height="48"
          width="48"
          src="@/assets/nois-logo.png"
        ></v-img
      ></v-toolbar-title>

      <v-spacer></v-spacer>
      <v-btn to="/login" text>
        <span class="mr-2">Accedi</span>
      </v-btn>
      <v-btn color="primary" to="/register">
        <span class="mr-2">Registrati</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view></router-view>
    </v-main>
    <v-container>
      <v-row justify="center">
        <v-col class="py-4 text-center" cols="2">
          <a href="https://noisplus.it/" target="_blank"
            ><v-img
              src="https://noisplus.it/wp-content/uploads/2022/05/nois-plus-gif.gif"
           ></v-img></a>
        </v-col>
        <v-col class="py-4 text-center" cols="2">
          <a href="https://www.comune.silanus.nu.it/" target="_blank"
            ><v-img
              src="https://noisplus.it/wp-content/uploads/2022/05/comune-silanus.gif"
            ></v-img></a>
        </v-col>

        <v-col class="py-4 text-center" cols="2">
          <a href="https://www.sardegnaricerche.it/" target="_blank"
            ><v-img
              src="https://noisplus.it/wp-content/uploads/2022/05/sardegna-ricerche.gif"
              ></v-img></a>
        </v-col>
      </v-row>

      <v-row justify="center">
        <v-col class="py-4 text-center" cols="3">
          <v-img
            src="https://noisplus.it/wp-content/uploads/2022/05/unione-europea-1.gif"
          ></v-img>
        </v-col>
        <v-col class="py-4 text-center" cols="3">
          <v-img
            src="https://noisplus.it/wp-content/uploads/2022/05/emblema-italia-3.gif"
          ></v-img>
        </v-col>

        <v-col class="py-4 text-center" cols="3">
          <v-img
            src="https://noisplus.it/wp-content/uploads/2022/05/regione-autonoma-di-sardegna-2.gif"
          ></v-img>
        </v-col>
        <v-col class="py-4 text-center" cols="3">
           <v-img
            src="https://noisplus.it/wp-content/uploads/2022/05/por-fesr.gif"
         ></v-img>
        
        </v-col>
      </v-row>
    </v-container>

    <v-snackbar
      v-model="uiStore.snackbar.show"
      :multi-line="true"
      :right="true"
      :top="true"
      :timeout="6000"
      :color="uiStore.snackbar.variant"
    >
      {{ uiStore.snackbar.message }}
      <v-btn dark text @click="uiStore.updateSnackbar({ show: false })">
        Chiudi
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapStores } from "pinia";
import { useUiStore } from "@/stores/UiStore";

export default {
  computed: {
    ...mapStores(useUiStore),
    username() {
      // We will see what `params` is shortly
      return this.$route.params.username;
    },
  },
  methods: {
    goToDashboard() {
      var isAuthenticated = false;
      if (isAuthenticated) {
        this.$router.push("/projects");
      } else {
        this.$router.push("/login");
      }
    },
  },
};
</script>