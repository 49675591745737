<template>
  <v-app>
    <v-app-bar app>
      <v-app-bar-nav-icon @click="drawer = true"></v-app-bar-nav-icon>
      <v-toolbar-title
        ><v-img
          contain
          height="48"
          width="48"
          src="@/assets/nois-logo.png"
        ></v-img
      ></v-toolbar-title>
      <v-spacer></v-spacer>

      <!-- {{userStore.hasUserRole('editor')}} -->

      {{ userStore.user.email }}

      <v-menu bottom left offset-y>
        <template v-slot:activator="{ on, attrs }">
          <v-btn icon v-bind="attrs" v-on="on">
            <v-icon>mdi-account</v-icon>
          </v-btn>
        </template>

        <v-list>
          <v-list-item
            v-for="(item, i) in menuItems"
            :key="i"
            @click="onMenuItem(item)"
          >
            <v-list-item-icon
              ><v-icon v-text="item.icon"></v-icon
            ></v-list-item-icon>
            <v-list-item-title>{{ item.title }}</v-list-item-title>
          </v-list-item>
        </v-list>
      </v-menu>
    </v-app-bar>

    <!-- absolute temporary -->
    <v-navigation-drawer v-model="drawer" app temporary>
      <v-toolbar color="primary" flat>
        <!-- v-list class="pa-0" dark>
          <v-list-tile avatar>
            <v-list-tile-avatar>
              
            </v-list-tile-avatar>
            <v-list-tile-content>
              <v-list-tile-title>user.organization</v-list-tile-title>
            </v-list-tile-content>
          </v-list-tile>
        </v-list -->
      </v-toolbar>

      <v-list nav>
        <v-list-item-group v-model="group">

          <v-list-item :to="'/projects'" >
            <v-list-item-icon>
              <v-icon>mdi-view-grid</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Esperienze</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="userStore.hasUserRole('superadmin')" :to="'/routes'">
            <v-list-item-icon>
              <v-icon>mdi-routes</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Percorsi</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/widgets'">
            <v-list-item-icon>
              <v-icon>mdi-widgets</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Widgets</v-list-item-title>
          </v-list-item>


          <v-list-item v-if="userStore.hasUserRole('admin')" :to="'/stats'">
            <v-list-item-icon>
              <v-icon>mdi-chart-bar</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Statistiche</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/profile'">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Profilo</v-list-item-title>
          </v-list-item>

          <v-list-item v-if="userStore.hasUserRole('admin')" :to="'/useradmin'">
            <v-list-item-icon>
              <v-icon>mdi-account-multiple</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Gestione utenti</v-list-item-title>
          </v-list-item>

          <v-list-item  v-if="userStore.hasUserRole('admin')" :to="'/configadmin'">
            <v-list-item-icon>
              <v-icon>mdi-cog</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Configurazione</v-list-item-title>
          </v-list-item>

          <v-list-item :to="'/help'">
            <v-list-item-icon>
              <v-icon>mdi-help</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Tutorial</v-list-item-title>
          </v-list-item>
        </v-list-item-group>

        <v-list-item></v-list-item>
      </v-list>
      <v-footer fixed app dark class="pa-3" height="auto">
        <div>v. {{appVersion}}</div>
        <v-spacer></v-spacer>
        <div>&copy; {{ new Date().getFullYear() }}</div>
      </v-footer>
    </v-navigation-drawer>

    <v-main>
      <router-view></router-view>
    </v-main>

    <!-- v-footer absolute class="pa-4">
      <v-spacer></v-spacer>
      &copy; {{ new Date().getFullYear() }} — <strong>NOIS plus</strong>
      <v-spacer></v-spacer>
    </v-footer -->

    <v-snackbar
      v-model="uiStore.snackbar.show"
      :multi-line="true"
      :right="true"
      :top="true"
      :timeout="6000"
      :color="uiStore.snackbar.variant"
    >
      {{ uiStore.snackbar.message }}
      <v-btn dark text @click="uiStore.updateSnackbar({ show: false })">
        Chiudi
      </v-btn>
    </v-snackbar>
  </v-app>
</template>

<script>
import { mapStores } from "pinia";
import { mapActions } from "pinia";

import { useUiStore } from "@/stores/UiStore";
import { useUserStore } from "@/stores/UserStore";
import {version} from '../../package'

export default {
  data: () => ({
    group: null,
    appVersion:version,
    drawer: null,
    menuItems: [
      {
        title: "Disconnetti",
        icon: "mdi-logout",
        action: "logout",
      },
      {
        title: "Guida",
        icon: "mdi-help",
        action: "help",
      },
      {
        title: "Il tuo profilo",
        icon: "mdi-account",
        action: "profile",
      },
    ],
  }),
  computed: {
    ...mapStores(useUiStore, useUserStore),
    ...mapActions(useUserStore, ["logout"]),
  },
  methods: {
    onMenuItem(item) {
      console.log(item);
      if (item.action == "logout") {
        this.userStore.logout();
      } else if (item.action == "help") {
        alert("Creare pagina documentazione");
      } else if (item.action == "profile") {
        this.goToPage("/profile");
      }
    },
    goToPage(pageName) {
      this.$router.push(pageName);
    },
  },
};
</script>


