<template>
  <v-container>
    <v-row>
      <v-col>
        <v-card>
          <v-card-text>
            <v-row>
              <v-col>
                <v-text-field
                  v-model="title"
                  dense
                  label="Titolo"
                  outlined
                ></v-text-field>
              </v-col>
              <v-col>
                <v-select
                  :items="tags"
                  item-text="label"
                  item-value="id"
                  v-model="tag"
                  dense
                  label="Tag"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-select
                :items="langs"
                  item-text="label"
                  item-value="id"
                  v-model="lng"
                  dense
                  label="Lingua"
                  outlined
                ></v-select>
              </v-col>
              <v-col>
                <v-btn class="mx-3" @click="generaCodice" color="primary"
                  >Genera Codice</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="gencode != ''">
      <v-col>
        <v-card>
          <v-card-title>Codice widget </v-card-title>
          <v-card-text>
            <pre><code>{{gencode}}</code></pre>
          </v-card-text>
          <!-- v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary">Copia codice negli appunti</v-btn>
            <v-btn color="primary">Visualizza anteprima</v-btn>
            <v-spacer></v-spacer>
          </v-card-actions -->
        </v-card>
      </v-col>
    </v-row>
    <v-row v-if="gencode != ''">
      <v-col>
        <iframe width="100%" height="400" :srcdoc="gencode"></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<style scoped>
pre {
  display: block;
  padding: 9.5px;
  margin: 0 0 10px;
  font-size: 13px;
  line-height: 20px;
  word-break: break-all;
  word-wrap: break-word;
  white-space: pre;
  white-space: pre-wrap;
  background-color: #f5f5f5;
  border: 1px solid #ccc;
  border: 1px solid rgba(0, 0, 0, 0.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
code {
  padding: 0;
  color: inherit;
  white-space: pre;
  white-space: pre-wrap;
  background-color: transparent !important;
  border: 0;
}
</style>

<script>
export default {
  name: "WidgetsView",

  data: () => ({
    version: "1,0",
    drawer: true,
    title: "Titolo",
    tag: "all",
    lng: "it",
    gencode: "",
    tags: [
      { label: "Mostra tutti", id: "all" },
      { label: "silanus", id: "silanus" },
      { label: "murales", id: "murales" },
    ],
    langs: [
      { label: "Italiano", id: "it" },
      { label: "Inglese", id: "en" },
    ],
  }),
  mounted() {
    //google.charts.load('current', { 'packages': ['line'] });
  },
  methods: {
    generaCodice() {
      this.gencode =
        "<!-- START NOIS plus widget V 1.1 -->\n" +
        '<div id="npw2"></div>\n' +
        '<script src="https://smartcms-8d342.web.app/api/widgets/listjs/npw2/' +
        this.lng +
        "/" +
        this.tag +
        "/" +
        this.title +
        '"><' +
        "/script>\n" +
        "<!-- START NOIS plus widget V 1.1 -->\n";
    },
  },
};
</script>
