 <template>
  <v-row class="my-3 text-center">
    <v-col>
      <v-list>
        <v-divider></v-divider>
        <v-draggable v-model="item.device.interaction.media">
          <template v-for="(media, index) in item.device.interaction.media">
            <div :key="index">
              <v-list-item>
                <v-list-item-avatar>
                  <v-icon v-text="iconType[media.type]"></v-icon>
                </v-list-item-avatar>

                <v-list-item-content>
                  <v-list-item-title
                    >Attivatore {{ index + 1 }}</v-list-item-title
                  >
                </v-list-item-content>

                <v-list-item-content>
                  <v-list-item-title>{{ media.title }}</v-list-item-title>
                </v-list-item-content>

                <v-list-item-content>
                  <audio :key="media.remoteurl" controls :src="media.remoteurl"></audio>
                </v-list-item-content>

                <v-list-item-action>
                  <span> </span>
                </v-list-item-action>

                <v-list-item-action>
                  <v-btn
                    outlined
                    class="my-0"
                    color="primary"
                    @click="openMediaEditor('remoteurl', media)"
                    >Seleziona file
                  </v-btn>
                </v-list-item-action>
                <!-- v-list-item-action>
                  <v-btn icon>
                    <v-icon color="grey lighten-1" @click="removeMedia(index)"
                      >mdi-delete</v-icon
                    >
                  </v-btn>
                </v-list-item-action -->
              </v-list-item>
              <v-divider></v-divider>
            </div>
          </template>
        </v-draggable>
      </v-list>
    </v-col>
  </v-row>
</template>
    
<script>
module.exports = {
  props: {
    item: Object,
    layout: Number,
    openMediaEditor: Function,
    lang: String,
  },
  data() {
    return {
      iconType: {
        text: "mdi-code-tags",
        video: "mdi-video",
        audio: "mdi-volume-high",
      },
    };
  },
  getters: {
    getlang() {
      if (this.lang) {
        return this.lang;
      } else {
        return "it";
      }
    },
  },
};
</script>