<template>
  <v-container>
    <div v-if="projectStore.loading">
      {{ projectStore.loading }}
    </div>

    <!-- {{ projectStore.project.app }} -->

    <template v-if="projectStore.loading == false">
      <v-form v-model="valid">
        <v-row :dense="form.dense">
          <v-col cols="8">
            <v-select
              :dense="form.dense"
              :outlined="form.outlined"
              :items="projectStore.languages"
              label="Lingua selezionata"
              v-model="projectStore.curLang"
              item-text="label"
              item-value="id"
            >
              <template v-slot:append-outer>
                <vs-lang-button :lang="projectStore.curLang"></vs-lang-button>
              </template>
            </v-select>

            <v-text-field
              :dense="form.dense"
              :outlined="form.outlined"
              v-model="
                $trlang.tr(projectStore.project.app, projectStore.curLang).title
              "
              label="Titolo"
              required
            >
              <template v-slot:append-outer>
                <vs-lang-button :lang="projectStore.curLang"></vs-lang-button>
              </template>
            </v-text-field>
            <v-text-field
              :dense="form.dense"
              :outlined="form.outlined"
              v-model="
                $trlang.tr(projectStore.project.app, projectStore.curLang)
                  .subtitle
              "
              label="Sottotitolo"
              required
            >
              <template v-slot:append-outer>
                <vs-lang-button :lang="projectStore.curLang"></vs-lang-button>
              </template>
            </v-text-field>
          </v-col>

          <v-col cols="4">
            <v-card hover>
              <v-img
                @click="openMediaEditor"
                max-height="250"
                contain
                :src="$imagekit.resize(projectStore.project.app.imgbanner, 800)"
              ></v-img>

              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="primary" @click="openMediaEditor"
                  >Carica immagine</v-btn
                >
                <v-spacer></v-spacer>
              </v-card-actions>
            </v-card>
          </v-col>
        </v-row>
        <v-row :dense="form.dense">
          <v-col cols="12"> </v-col>
        </v-row>
        <v-row :dense="form.dense">
          <v-col cols="12">
            <vs-lang-button :lang="projectStore.curLang"></vs-lang-button>

            <tiptap-vuetify
              v-model="
                $trlang.tr(projectStore.project.app, projectStore.curLang)
                  .description
              "
              :extensions="extensions"
            />
          </v-col>
        </v-row>
        <!-- SECTIONS -->
        <v-row v-if="projectStore.curLang == 'it'">
          <v-col>
            <v-menu offset-y>
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" v-bind="attrs" v-on="on">
                  Aggiungi Contenuto
                </v-btn>
              </template>
              <v-list>
                <v-list-item
                  @click="addSection(item)"
                  v-for="(item, index) in contentTypes"
                  :key="index"
                >
                  <v-list-item-icon
                    ><v-icon v-text="item.icon"></v-icon
                  ></v-list-item-icon>
                  <v-list-item-title>{{ item.title }} </v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </v-col>
        </v-row>

        <v-row v-if="projectStore.curLang !== 'it'">
          <v-col>
            <v-alert type="success"
              >Per aggiungere un nuovo contenuto seleziona la lingua
              italiana.</v-alert
            >
          </v-col>
        </v-row>

        <v-list>
          <v-divider></v-divider>
          <v-draggable v-model="projectStore.project.app.sections">
            <template
              v-for="(section, index) in projectStore.project.app.sections"
            >
              <div :key="index">
                <v-list-item>
                  <v-list-item-avatar tile size="100">
                    <v-img
                      :src="
                        $imagekit.resize(section.infoItemPreviewPicture, 200)
                      "
                    >
                    </v-img>
                  </v-list-item-avatar>
                  <v-list-item-avatar>
                    <v-icon
                      v-text="getIconByType(section.infoItemType)"
                    ></v-icon>
                  </v-list-item-avatar>
                  <v-list-item-content>
                    <v-list-item-title
                      >{{ $trlang.tr(section, projectStore.curLang).infoItemTitle }}
                    </v-list-item-title>
                    <v-list-item-subtitle
                      >{{$trlang.tr(section, projectStore.curLang).infoItemSubTitle }}
                    </v-list-item-subtitle>
                  </v-list-item-content>

                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon
                        color="grey lighten-1"
                        @click="editSection(section, index)"
                        >mdi-pencil</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <v-btn icon>
                      <v-icon
                        color="grey lighten-1"
                        @click="removeSection(index)"
                        >mdi-delete</v-icon
                      >
                    </v-btn>
                  </v-list-item-action>
                  <v-list-item-action>
                    <span> </span>
                  </v-list-item-action>
                  <v-list-item-action>
                    <vs-lang-button
                      :lang="projectStore.curLang"
                    ></vs-lang-button>
                  </v-list-item-action>
                </v-list-item>
                <v-divider></v-divider>
              </div>
            </template>
          </v-draggable>
        </v-list>
      </v-form>
    </template>

    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectImage"
    ></vs-media-editor>
    
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
// import the component and the necessary extensions
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";
import HtmlComponent from "../../mixins/htmlcomponent";

export default {
  components: {
    ConfirmDlg: () => import("../../components/ui/ConfirmDlg"),
  },
  mixins: [FormTheme, HtmlComponent],
  props: ["id"],
  name: "ProjectApp",
  data: () => ({
    valid: true,
    contentTypes: [
      { title: "Gallery", type: "mediaPicture", icon: "mdi-view-gallery" },
      { title: "Foto 360°", type: "media360", icon: "mdi-panorama-variant" },
      { title: "Audio", type: "mediaAudio", icon: "mdi-volume-high" },
      { title: "Video", type: "mediaVideo", icon: "mdi-video" },
      {
        title: "Gioco Quiz",
        type: "interactiveGame",
        icon: "mdi-gamepad-variant",
      },
      {
        title: "Incorpora Contenuto",
        type: "embedContent",
        icon: "mdi-code-tags",
      },
    ],
    iconTypes: {
      mediaPicture: "mdi-view-gallery",
      media360: "mdi-panorama-variant",
      mediaAudio: "mdi-volume-high",
      mediaVideo: "mdi-video",
      interactiveGame: "mdi-gamepad-variant",
      embedContent: "mdi-code-tags",
    },
    routeTypes: {
      mediaPicture: "projectappgallery",
      media360: "projectapppano",
      mediaAudio: "projectappaudio",
      mediaVideo: "projectappvideo",
      interactiveGame: "projectappquiz",
      embedContent: "projectappembed",
    },
  }),
  mounted() {
    this.projectStore.loadProject(this.id);
  },
  computed: {
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  methods: {
    openMediaEditor() {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        {}
      );
    },
    getIconByType(type) {
      return this.iconTypes[type];
    },
    onSelectImage(evn) {
      //
      console.log("onSelectImage: ", evn);
      this.projectStore.project.app.imgbanner = evn.url;
    },
    editSection(item, index) {
      this.$router.push({
        name: this.routeTypes[item.infoItemType],
        params: { index: index },
      });
    },
    /*updateProject() {
      this.projectStore.
    },*/
    addSection(item) {
      var infoItem = JSON.parse(JSON.stringify(this.configStore.emptyInfoItem));
      infoItem.infoItemTitle = item.title;
      infoItem.infoItemType = item.type;
      this.projectStore.project.app.sections.push(infoItem);
    },
    async removeSection(index) {
      if (
        await this.$refs.confirm.open(
          "Conferma",
          "Sei sicuro di voler rimuovere questa sezione ?"
        )
      ) {
        this.projectStore.project.app.sections.splice(index, 1);
      }
    },
  },
};
</script>
