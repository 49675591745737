
<template>
  <v-dialog v-model="dialog" fullscreen style="z-index: 8000 !important">
    <v-card no-gutter>
      <v-toolbar dark color="primary">
        <v-btn icon dark @click="dialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-toolbar-title>Libreria Multimediale</v-toolbar-title>
      </v-toolbar>
      <v-row align="center" class="ma-8">
        <!-- v-col>
          <v-breadcrumbs large :items="bcitems">
            <template v-slot:divider>
              <v-icon>mdi-chevron-right</v-icon>
            </template>
          </v-breadcrumbs>
        </v-col -->
        <v-col cols="3">
          <v-select
            dense
            v-model="curRef"
            :items="folders"
            item-text="name"
            item-value="path"
            prepend-icon="mdi-plus"
            menu-props="auto"
            hide-details
            label="Seleziona cartella"
            @click:prepend="addFolder"
            @change="getFileList"
            
          ></v-select>
          <!-- {{curRef}} -->
        </v-col>
        
        <v-col cols="3">
          <v-text-field
            
            v-model="searchValue"
            label="Ricerca"
          ></v-text-field>
          <!-- {{curRef}} -->
        </v-col>
        
        <v-col cols="3">
          
          <!-- {{curRef}} -->
        </v-col>

        <v-col cols="3" style="text-align:right">
          <!-- v-file-input
              show-size
              v-model="file"
              truncate-length="30"
              hidden
              hide-input
            ></v-file-input -->

          <v-file-input
            counter
            label="Seleziona i file da caricare"
            v-model="files"
            multiple
            show-size
            truncate-length="30"
            @change="uploadMultipleFiles()"
          ></v-file-input>

          <!-- hide-input -->

          <!-- {{ getFileState() }} -->
        </v-col>
        <v-col>
          <v-card v-show="uploadstate">
            <v-card-text>{{ uploadstate }}</v-card-text>
          </v-card>
        </v-col>
        <v-col>
          <v-progress-linear
            v-show="uploadstate"
            v-model="uploadprogress"
            height="25"
          >
            <template v-slot:default="{ value }">
              <strong>{{ Math.ceil(value) }}%</strong>
            </template>
          </v-progress-linear>
        </v-col>
        <!-- v-col>
          <v-btn @click="uploadFile()">Carica File</v-btn>
        </v-col -->
      </v-row>

      <!-- v-row class="ma-8">
        <v-col v-show="curRef != ''" cols="3">
          <v-card>
            <v-card-title @click="changeMediaFolder('')">Home</v-card-title>
          </v-card>
        </v-col>
        <v-col v-for="(item, index) in getFolders()" :key="index" cols="3">
          <v-card>
            <v-card-title @click="changeMediaFolder(item.name)">
              {{ item.name }}
            </v-card-title>
          </v-card>
        </v-col>
      </v-row -->

      <v-row
        align="center"
        v-if="mediaItems.length == 0 && curRef !== ''"
        class="ma-8"
      >
        <v-col align="center">
          <v-card max-width="600" style="text-align: center">
            <v-card-title>Nessul file presente in questa cartella</v-card-title>
            <v-card-actions>
              <v-spacer>
                <v-btn color="secondary" @click="deleteCurrentFolder()"
                  ><v-icon class="mr-4">mdi-delete</v-icon> Elimina
                  Cartella</v-btn
                >
              </v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>

      <v-row class="ma-8">
        <v-col v-for="(item, index) in searchResult" :key="index" cols="3">
          <v-card style="text-align: center">
            <v-img
              v-if="isType(item.metadata.contentType, 'image')"
              contain
              height="150"
              :src="item.url"
            ></v-img>
            <video
              :key="item.url"
              height="150"
              controls
              v-if="isType(item.metadata.contentType, 'video')"
            >
              <source :src="item.url" :type="item.metadata.contentType" />
              Il tuo browser non supporta il tag video.
            </video>

            <v-sheet
              v-if="isType(item.metadata.contentType, 'audio')"
              color="white"
              elevation="0"
              height="150"
            >
              <audio :key="item.url" controls class="ma-4">
                <source :src="item.url" :type="item.metadata.contentType" />
                Il tuo browser non supporta il tag audio.
              </audio>
            </v-sheet>

            <v-img
              v-if="isType(item.metadata.contentType, 'pdf')"
              contain
              height="150"
              src="https://via.placeholder.com/300x150/333333/FFFFFF?text=PDF"
            ></v-img>

            <v-card-subtitle><a :href="item.url">{{ item.name }}</a></v-card-subtitle>
            <!-- v-card-text>{{ item.metadata.contentType }}</v-card-text -->
            <!-- v-card-text>{{item}}</v-card-text -->
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="primary" @click="selectImage(item)"
                >Seleziona</v-btn
              >
              <v-btn color="secondary" @click="deleteImage(item, index)">
                Elimina
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </v-row>
    </v-card>
    <ConfirmDlg ref="confirm" />
    <EditDlg ref="editdlg" />
  </v-dialog>
</template>

<script>
// getMetadata,
import {
  getStorage,
  ref,
  uploadBytesResumable,
  uploadString,
  getDownloadURL,
  getMetadata,
  deleteObject,
  listAll,
} from "firebase/storage";

export default {
  //props: ["src", "url", "rootfolder"],
  data() {
    return {
      uploadstate: "",
      searchValue: "",
      uploadprogress: 0,
      dialog: false,
      options: {},
      files: [],
      file: null,
      rootfolder: "",
      img: "",
      fileState: { state: "", "progress: ": 0, error: "" },
      curRef: "",
      mediaItems: [],
      folders: [],
      bcitems: [
        {
          text: "Home",
          link: true,
        },
      ],
    };
  },
  mounted() {
    // Take a local copy of the project
  },
  computed: {
    getText() {
      return this.value;
    },
    searchResult() {
      let tempItems = this.mediaItems;

      if (this.searchValue != "" && this.searchValue) {
        tempItems = tempItems.filter((item) => {
          return item.name
            .toUpperCase()
            .includes(this.searchValue.toUpperCase());
        });
      }

      tempItems = tempItems.sort((a, b) => {
        let fa = a.name.toLowerCase();
        let fb = b.name.toLowerCase();

        if (fa < fb) {
          return -1;
        }
        if (fa > fb) {
          return 1;
        }
        return 0;
      });

      return tempItems;
    },
  },
  components: {
    ConfirmDlg: () => import("../ui/ConfirmDlg"),
    EditDlg: () => import("../ui/EditDlg"),
  },
  methods: {
    isType(mimeType, type) {
      return mimeType.includes(type);
    },
    open(rootfolder, img, options) {
      this.rootfolder = rootfolder;
      this.img = img;
      this.options = Object.assign(this.options, options);
      /*return new Promise((resolve, reject) => {
          this.resolve = resolve;
          this.reject = reject;
        });*/
      this.dialog = true;

      this.getFileList();
    },
    async deleteCurrentFolder() {
      const vm = this;

      // non deve cancellare la cartella home...
      if (vm.curRef == "") return;

      if (
        await this.$refs.confirm.open(
          "Conferma",
          "Sei sicuro di voler rimuovere la cartella ?"
        )
      ) {
        const storage = getStorage();
        const fileRef = ref(
          storage,
          vm.rootfolder + vm.curRef + "/_foldermeta.json"
        );
        console.log("deleteFile: ");
        // Get metadata properties
        // Delete the file
        await deleteObject(fileRef);
        vm.curRef = "";
        this.getFileList();
      }
    },
    async uploadMultipleFiles() {
      const vm = this;
      console.log(vm.files.length);
      for (var i = 0; i < this.files.length; i++) {
        vm.uploadstate = `${i}/${vm.files.length}`;
        await vm.uploadFile(this.files[i]);
      }
      this.getFileList();
    },
    uploadFile(file) {
      const vm = this;
      const storage = getStorage();
      // Upload file and metadata to the object 'images/mountains.jpg'
      const folder = this.rootfolder + this.curRef + "/" + file.name;

      console.log("uploadFile: ", folder);

      return new Promise((resolve) => {
        const storageRef = ref(storage, folder);
        //const uploadTask = uploadBytesResumable(storageRef, file, metadata);
        const uploadTask = uploadBytesResumable(storageRef, file);

        // Listen for state changes, errors, and completion of the upload.
        uploadTask.on(
          "state_changed",
          (snapshot) => {
            // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
            const progress =
              (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
            console.log("Upload is " + progress + "% done");
            vm.uploadprogress = progress;

            switch (snapshot.state) {
              case "paused":
                console.log("Upload is paused");
                break;
              case "running":
                console.log("Upload is running");
                break;
            }
          },
          (error) => {
            // A full list of error codes is available at
            // https://firebase.google.com/docs/storage/web/handle-errors
            switch (error.code) {
              case "storage/unauthorized":
                resolve("rejected");
                break;
              case "storage/canceled":
                resolve("rejected");
                break;
              case "storage/unknown":
                resolve("rejected");
                break;
            }
          },
          () => {
            getDownloadURL(uploadTask.snapshot.ref).then((downloadURL) => {
              console.log("File available at", downloadURL);
              resolve("resolved");
            });
          }
        );
      });
    },
    async deleteImage(image, index) {
      const vm = this;
      if (
        await this.$refs.confirm.open(
          "Conferma",
          "Sei sicuro di voler rimuovere il file ?"
        )
      ) {
        const storage = getStorage();
        const fileRef = ref(storage, image.fullpath);
        console.log("deleteFile: ");
        // Get metadata properties
        // Delete the file
        deleteObject(fileRef)
          .then(() => {
            // File deleted successfully
            //commit('setFileDeleted', {});
            vm.mediaItems.splice(index, 1);
          })
          .catch((error) => {
            // Uh-oh, an error occurred!
            console.log(error);
          });
      }
    },
    getFileState() {
      return this.fileState;
    },
    openMediaList() {
      this.getFileList();
      this.dialog = true;
    },
    async getFileList() {
      const vm = this;

      const storage = getStorage();
      // Create a reference under which you want to list
      console.log("getFileList: ", this.rootfolder + this.curRef);

      const listRef = ref(storage, this.rootfolder + this.curRef);

      listAll(listRef)
        .then(async (res) => {
          vm.mediaItems = [];

          // Per ora solo un livello di folder
          if (vm.curRef == "") {
            vm.folders = [{ name: "Home", path: "" }];
            res.prefixes.forEach((folderRef) => {
              console.log(folderRef);
              if (folderRef.name !== "resized") {
                vm.folders.push({
                  name: folderRef.name,
                  path: "/" + folderRef.name,
                });
              }
            });
          }

          res.items.forEach(async (itemRef) => {
            var url = await getDownloadURL(itemRef);
            var metadata = await getMetadata(itemRef);
            if (itemRef.name !== "_foldermeta.json") {
              vm.mediaItems.push({
                name: itemRef.name,
                fullpath: itemRef.fullPath,
                url: url,
                metadata: metadata,
              });
            }
          });
          vm.uploadstate = "";
          vm.files = [];
          //commit('setFileList', { 'folders': folders, 'items': mediaItems });
        })
        .catch((error) => {
          // Uh-oh, an error occurred!
          console.log(error);
        });
    },
    async addFolder() {
      var newFolder = await this.$refs.editdlg.open(
        "Nuova cartella",
        "Inserire il nome della nuova cartella"
      );

      if (newFolder !== "") {
        const storage = getStorage();
        const storageRef = ref(
          storage,
          this.rootfolder + "/" + newFolder + "/_foldermeta.json"
        );
        const jsonString = JSON.stringify({ text: "folder placeholder" });
        const result = uploadString(storageRef, jsonString);
        console.log("Uploaded a raw string!", result);
      }
    },
    getImages() {
      return this.items;
    },
    getFolders() {
      return this.folders;
    },
    selectImage(item) {
      console.log("selectImage: ", item);
      this.$emit("selectimage", {
        url: item.url,
        name: item.name,
        fullpath: item.fullpath,
        metadata: item.metadata,
        options: this.options,
      });
      this.dialog = false;
    },
  },
};
</script>