<template>
  <router-view />
</template>

<script>
export default {
  name: "App",
};
</script>

<style>
.v-card__text,
.v-card__title {
  word-break: normal !important; /* maybe !important  */
}
.v-main {
  padding: 64px 0px 0px !important;
}
</style>