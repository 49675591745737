<template>
  <v-container>
    <vs-info-item-part
      :openMediaEditor="openMediaEditor"
      :item="item"
      :layout="6"
      :lang="projectStore.curLang"
    >
    </vs-info-item-part>

    <v-row class="text-center">
      <v-col>
        <v-card>
          <v-card-title>Video</v-card-title>
          <v-card-text>
            <video style="max-width:100%"
              :key="item.infoItemMediaItem[0]"
              :src="item.infoItemMediaItem[0]" 
              controls></video>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn
              text
              class="my-0"
              color="primary"
              @click="openMediaEditor('video', item)"
              >Seleziona
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectMedia"
    ></vs-media-editor>
  </v-container>
</template>
<style scoped>
iframe {
  aspect-ratio: 16 / 9;
  height: 100%;
  width: 100%;
  border: none;
}
</style>
<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";

export default {
  props: ["index"],
  name: "EditorVideo",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
    htmlDoc: "",
    videoType: [
      { text: "YouTube", value: "youtube" },
      { text: "Vimeo", value: "vimeo" },
      { text: "Video MP4", value: "video" },
    ],
  }),
  computed: {
    item() {
      return this.projectStore.project.app.sections[this.index];
    },
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  mounted() {
    // Do something during load
  },
  methods: {
    openMediaEditor(resname, item) {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        { resname: resname, item: item }
      );
    },
    onSelectMedia(evn) {
      console.log("onSelectImage: ", evn);
      if (evn.options.resname == "video") {
        if (evn.options.item.infoItemMediaItem.length <= 0) {
          evn.options.item.infoItemMediaItem.push("");
        }
        evn.options.item.infoItemMediaItem[0] = evn.url;
      } else if (evn.options.resname == "infoItemMainPicture") {
        this.item.infoItemPreviewPicture = evn.url;
        this.item.infoItemMainPicture = evn.url;
      }  else {
        evn.options.item[evn.options.resname] = evn.url;
      }
    },
    loadEmbed() {
      this.htmlDoc = ```<!DOCTYPE html>
            <html>
              <head>
                <title>Incorpora Contenuto</title>
                <meta name="viewport" content="width=device-width, initial-scale=1.0">
              </head>
              <body>
              <style>
                * {
                  margin: 0;
                  padding: 0;
                }
                html, 
                body {
                    height: 100%;
                }
                iframe {
                  aspect-ratio: 1 / 1;
                  height: 100%;
                  width: 100%;
                }
              </style>
                ${this.item.infoItemEmbed}
              </body>
            </html>```;
      //Method placeholder
    },
  },
};
</script>
