import { defineStore } from 'pinia'

// useStore could be anything like useUser, useCart
// the first argument is a unique id of the store across your application
export const useUiStore = defineStore({
    // state
    id: 'ui',
    state: () => {
        return {
            snackbar: {
                show: false,
                variant: 'success',
                message: 'Elemento salvato con successo.'
            },
        }
    },
    actions: {
        updateSnackbar(settings) {
            this.snackbar = {
                ...this.snackbar,
                ...settings
            }

        }
    }

    // getters
})