<template>
  <v-container>
    <vs-info-item-part
      :openMediaEditor="openMediaEditor"
      :item="item"
      :layout="6"
      :lang="projectStore.curLang"
    >
    </vs-info-item-part>
    <vs-media-editor
      ref="mediaeditor"
      @selectimage="onSelectMedia"
    ></vs-media-editor>

    <!-- v-layout class="mt-5 align-center justify-center row fill-height">
      <h2>Parent Component</h2>
    </v-layout -->

    <v-row>
      <!-- v-draggable v-model="item.infoItemMediaItem" tag="v-layout" -->
      <template v-for="(mediaItem, mediaItemIndex) in item.infoItemMediaItem">
        <v-col md="3" :key="'mediaitem-' + mediaItemIndex">
          <v-card class="itemcard">
            <v-img height="150" :src="$imagekit.resize(mediaItem, 300)"></v-img>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn
                text
                class="my-0"
                color="primary"
                @click="openMediaEditor('gallery', item, mediaItemIndex)"
                >Seleziona
              </v-btn>
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-col>
      </template>
      <!-- /v-draggable -->

      <v-col md="3">
        <v-card>
          <v-img
            @click="addMediaItem()"
            height="150"
            src="https://via.placeholder.com/150/eee/ccc?text=+"
          >
            <v-row class="fill-height ma-0" align="center" justify="center">
              <v-icon x-large>mdi-plus</v-icon>
            </v-row>
          </v-img>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn text class="my-0" color="primary" @click="addMediaItem()"
              >Aggiungi
            </v-btn>
            <v-spacer></v-spacer>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-row>

    <!-- code>{{ item }}</code -->
  </v-container>
</template>

<script>
import { mapStores } from "pinia";

import { useUserStore } from "@/stores/UserStore";
import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";

import FormTheme from "../../mixins/formtheme";

export default {
  props: ["index"],
  name: "EditorGallery",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
  }),
  mounted() {},
  computed: {
    item() {
      return this.projectStore.project.app.sections[this.index];
    },
    ...mapStores(useUserStore, useConfigStore, useProjectStore),
  },
  methods: {
    addMediaItem() {
      this.item.infoItemMediaItem.push("");
    },
    removeMediaItem(index) {
      this.item.infoItemMediaItem.splice(index, 1);
    },
    openMediaEditor(resname, item, index) {
      this.$refs.mediaeditor.open(
        this.userStore.curGroup,
        this.projectStore.project.img,
        { resname: resname, item: item, index: index }
      );
    },
    onSelectMedia(evn) {
      console.log("onSelectImage: ", evn);
      if (evn.options.resname == "gallery") {
        if (evn.options.item.infoItemMediaItem.length <= 0) {
          evn.options.item.infoItemMediaItem.push("");
        }
        //this.item.infoItemMediaItem[evn.options.index] = evn.url;
        this.$set(this.item.infoItemMediaItem, evn.options.index, evn.url);
      } else if (evn.options.resname == "infoItemMainPicture") {
        this.item.infoItemPreviewPicture = evn.url;
        this.item.infoItemMainPicture = evn.url;
      } else {
        evn.options.item[evn.options.resname] = evn.url;
      }
      
    },
  },
};
</script>
