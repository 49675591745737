<template>
  <v-container>
   <h3>Gestione percorsi</h3>
   <ul>
     <li>Aggiungi/Rimuovi percorso</li>
     <li>Aggiungi/Rimuove tappe al percorso </li>
     <li>Drag & Drop per riordinarle </li>
   </ul>
  </v-container>
</template>

<script>
export default {
  name: "RoutesView",

  data: () => ({
    version: "1,0",
  }),
  mounted() {
    // Load all experiences (or the latest experiences ...)
  },
  methods: {
    example() {
      //Method placeholder
    },
  },
};
</script>
