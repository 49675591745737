<template>
  <v-container>
    <!-- template v-if="userStore.hasUserRole('editor')" -->
    <h2 v-if="userStore.hasUserRole('editor')">Le mie esperienze</h2>
    <v-row v-if="userStore.hasUserRole('editor')">
      <v-col cols="6">
        <!-- v-btn color="primary" @click="projectStore.addProject" class="mt-2"
          >Aggiungi un esperienza</v-btn
        -->
        <v-btn color="primary" @click="newProject()" class="mt-2"
          >Aggiungi un esperienza</v-btn>
      </v-col>
      <v-col cols="6">
        <!-- {{userStore.getUserGroups}} -->
        <!-- {{ userStore.curGroup }} -->
        <v-select
          @change="projectStore.loadProjects('from projects view')"
          v-model="userStore.curGroup"
          :items="userStore.getUserGroups"
          label="Gruppo selezionato:"
          item-text="label"
          item-value="id"
        ></v-select>
      </v-col>
    </v-row>

    <v-row class="text-center" v-if="userStore.hasUserRole('editor')" >
      <v-col
        v-for="(item, index) in projectStore.projects"
        :key="index"
        class="d-flex child-flex"
        cols="3"
      >
        <vs-project-card
          :item="item"
          :editProject="projectStore.editProject"
          :actionProject="localActionProject"
        ></vs-project-card>
      </v-col>
    </v-row>
    <!-- /template -->

    <v-row v-if="userStore.hasUserRole('editor') == false">
      <v-col>
        <RequestActivation ref="activation" />
      </v-col>
    </v-row>
    <SelectmodelDlg ref="selectmodel" />
    <ChangegroupDlg ref="changegroup" />
    <ConfirmDlg ref="confirm" />
  </v-container>
</template>

<script>
import FormTheme from "../mixins/formtheme"; // TODO vedere come è meglio strutturarlo

import { mapStores } from "pinia";
//import { mapActions } from "pinia";

import { useConfigStore } from "@/stores/ConfigStore";
import { useProjectStore } from "@/stores/ProjectStore";
import { useUserStore } from "@/stores/UserStore";
//import {configStore} from ".."

export default {
  name: "ProjectsView",
  mixins: [FormTheme],
  data: () => ({
    version: "1,0",
    namedata: "test",
  }),
  mounted() {
    this.projectStore.loadModels();
    this.projectStore.loadProjects("ProjectView Mounted");
    
  },
  components: {
    ConfirmDlg: () => import("../components/ui/ConfirmDlg"),
    ChangegroupDlg: () => import("../components/ui/ChangegroupDlg"),
    SelectmodelDlg: () => import("../components/ui/SelectmodelDlg"),
    RequestActivation: () => import("../components/ui/RequestActivation"),
  },
  computed: {
    ...mapStores(useConfigStore, useProjectStore, useUserStore),
    /*...mapActions(useProjectStore, [
      "addProject",
      "deleteProject",
      "editProject",
      "cloneProject"
    ]),*/
  },
  methods: {
    async newProject() {
      const vm = this;
       var selproject = await this.$refs.selectmodel.open(
          "Nuovo progetto",
          "Seleziona un modello da cui partire.",
          vm.projectStore.models,
           {width: 1280}
        );

        if (selproject != "" ) {
          console.log(selproject)
          vm.projectStore.cloneProject({
            id: selproject.id,
            groupId: vm.userStore.curGroup,
            modelId: selproject.id
          });
        }
    },

    async localActionProject(id, action) {
      const vm = this;
      if (action == "delete") {
        if (
          await this.$refs.confirm.open(
            "Conferma",
            "Sei sicuro di voler rimuovere il progetto ?"
          )
        ) {
          vm.projectStore.deleteProject({
            id: id,
          });
        }
      }

      if (action == "clone") {
        if (
          await this.$refs.confirm.open(
            "Conferma",
            "Sei sicuro di voler copiare il progetto ?"
          )
        ) {
          vm.projectStore.cloneProject({
            id: id,
            groupId: vm.userStore.curGroup,
            modelId: "",
          });
        }
      }

      if (action == "move") {
        var selgroup = await this.$refs.changegroup.open(
          "Sposta",
          "Sei sicuro di voler spostare il progetto ?",
          vm.userStore.getUserGroups
        );

        if (selgroup != "" && selgroup != vm.userStore.curGroup) {
          vm.projectStore.changeProjectGroup({
            id: id,
            groupId: selgroup,
          });
        }
      }

      /*if (action == "clone") {
        
      }*/
    },
  },
};
</script>
